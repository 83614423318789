import { AllPayload, Sensor, sensorRepository, CreateSensorDto, SensorInterface, UpdateSensorDto, SensorAllPayload } from '@/store/modules';
import { SensorState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { filter } from 'rxjs/operators';
import { AnyObject } from '../../../utility/types';
import { AddressInterface } from '../Address/Address';

@Module
export class SensorModule extends VuexModule implements SensorState {

  @Action({ rawError: true })
  async all(payload: SensorAllPayload) {
    Sensor.commit(state => {
      state.fetching = true;
      state.error.length = 0;
      state.data = [];
    });


    try {
      console.log('in sensor all action')
      const { status, data } = await sensorRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { total, results } = data;
      // let {total} = data;
      // if (typeof total === 'undefined') {
      //   total = results.length;
      // }

      // if (payload.limit && results.length > payload.limit)  {
      //   const offset = payload.offset || 0;
      //   results = results.splice(offset, payload.limit);
      // }

      // the following is a "hack" for demo purposes. Ideally we need to rework this whole thing
      const { results: otherResults }: { results: Array<AnyObject & { address: AddressInterface | null, sensor_uuid: string }> } =
        await fetch('https://addressdemo.kbmercury.com/sensor')
          .then(r => r.json());
      const mappedResults = await Promise.all(results.map(async (sensor) => {
        const f = otherResults.filter(({ sensor_uuid }) => sensor_uuid === sensor.deviceEUI);
        if (!f || !f.length) {
          return sensor;
        }
        return {
          ...sensor,
          address: f[0].address
        }
      }));

      await Sensor.create({
        data: payload.filterFn ? mappedResults.filter(payload.filterFn) : mappedResults
      });

      Sensor.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      console.error(err);

      const e = (normalizeError(err) || err);
      Sensor.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    Sensor.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await sensorRepository.findOne(payload.id);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }
      const data = response.data;

      const { data: metricData } = await sensorRepository.getLatestMetric(data.deviceEUI)

      if (!data.metric) {
        data.metric = metricData ? metricData.results : [];
      }

      // the following is a "hack" for demo purposes. Ideally we need to rework this whole thing
      const otherData: AnyObject & { address: AddressInterface | null, sensor_uuid: string } =
        await fetch(`https://addressdemo.kbmercury.com/sensor/${data.deviceEUI}`)
          .then(r => r.json());

      const mappedData = {
        ...data,
        address: otherData ? otherData.address : null
      };
      // const mappedData = await Promise.all(results.map(async (sensor) => {
      //       const f = otherResults.filter(({ sensor_uuid }) => sensor_uuid === sensor.deviceEUI);
      //       if (!f || !f.length) {
      //         return sensor;
      //       }
      //       return {
      //         ...data,
      //         address: f[0].address
      //       }
      //     }));
      await Sensor.insert({
        data: mappedData
      });
      // console.log(payload.id, 'sensor add', data)
      Sensor.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Sensor.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

  }

  @Action
  async findByMR(payload: { recordId: number }) {
    Sensor.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await sensorRepository.findByMR(payload.recordId);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: create toggle? instead of just inserting
      if (data.results) {
        Sensor.insert({
          data: data.results
        });
      }

      Sensor.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Sensor.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async createSensor(payload: { sensor: Sensor }) {
    const { sensor } = payload;
    const dto: CreateSensorDto = {
      name: sensor.name,
      deviceEUI: sensor.deviceEUI || ''
    };

    Sensor.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      const response = await sensorRepository.create(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      await Sensor.insert({ data });

      console.log('create  complete');
      Sensor.commit(state => {
        state.saving = false;
        state.error.length = 0;
        state.lastInsertedId = data.id;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Sensor.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async updateSensor(payload: { sensor: SensorInterface }) {
    const { sensor } = payload;

    Sensor.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      if (!sensor.id) {
        throw 'Missing sensor id';
        return;
      }

      const dto: UpdateSensorDto = {
        id: sensor.id,
        name: sensor.name,
        deviceEUI: sensor.deviceEUI || '',
      };

      const response = await sensorRepository.update(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      // TODO update response does not return a valid payload. Treating as no content for now. 
      // if it returned the updated sensor, we can call Sensor.insertOrUpdate({data: [data]});
      // await Sensor.insertOrUpdate({data: [data]});

      console.log('put  complete', data);

      Sensor.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Sensor.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }
}

export default SensorModule;
// export default getModule(SensorModule);