import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { Technician } from '../Technician';
import { Part } from '..';

export interface PartSoldInterface {
  id: number;
  tech_id?: number;
  mr_id?: number;
  task_id?: number;
  job_id?: number;
  part_id?: number;
  cost?: number;
  created_at?: string;
  updated_at?: string;
  tech_name?: string;
  jobNumber?: string;
  taskNumber?: string;
  install_note?: string;
  warranty_start_date?: string;
  warranty_replacement?: number;
  quantity?: number;
  fulfilled?: boolean;
  part?: Part;

  // embedded data
  info?: Part;
}

export class PartSold extends Model implements PartSoldInterface {
  id!: number;
  tech_id?: number;
  mr_id?: number;
  task_id?: number;
  job_id?: number;
  part_id?: number;
  created_at?: string;
  updated_at?: string;
  tech_name?: string;
  jobNumber?: string;
  taskNumber?: string;
  install_note?: string;
  warranty_start_date?: string;
  warranty_replacement?: number;
  quantity?: number;
  fulfilled?: boolean;
  cost?: number;
  part?: Part;

  static entity = 'partSold';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      tech_name: this.string(null).nullable(),
      jobNumber: this.string(null).nullable(),
      taskNumber: this.string(null).nullable(),
      install_note: this.string(null).nullable(),
      warranty_start_date: this.string(null).nullable(),
      warranty_replacement: this.string(null).nullable(),

      mr_id: this.number(null).nullable(),
      tech_id: this.number(null).nullable(),
      task_id: this.number(null).nullable(),
      job_id: this.number(null).nullable(),
      part_id: this.number(null).nullable(),
      quantity: this.number(null).nullable(),
      fulfilled: this.boolean(null).nullable(),
      cost: this.number(null).nullable(),

      mr: this.belongsTo(MR, 'mr_id', 'id'),
      technician: this.belongsTo(Technician, 'tech_id', 'ID'),
      part: this.belongsTo(Part, 'part_id', 'id')
    }
  }
}

export default PartSold;