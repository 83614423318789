import { AllPayload, PartRequest } from '@/store/modules';
import { PartRequestState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { from } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import Part from '../Part/Part';
import { PartRequestInterface } from './PartRequest';
import { partRequestRepository, PartRequestFlag } from './repository';
import { AxiosResponse } from 'axios';

type ToggleFlagPayload = { id: number; flag: PartRequestFlag };

async function pullParts(payload: { requests: PartRequestInterface[] }) {
  return new Promise((ok, fail) => {
    const src$ = from<PartRequestInterface[]>(payload.requests).pipe(
      concatMap((record) => {
        if (!record.part_id) {
          return Promise.resolve(null);
        }

        if (Part.query().where('id', record.part_id).exists()) {
          return Promise.resolve(record.part_id);
        }
        return Part.dispatch('findOne', { id: +record.part_id }).then(() => record.part_id);

        // return forkJoin(...requests.map(record => {
        //   if (!record.part_id) {
        //     return of(null);
        //   }
        //   console.log(record.part_id, Part.query().where('id', record.part_id).exists())
        //   return Part.dispatch('findOne', { id: +record.part_id })
        // }));
      }),
      toArray()
    );

    src$.subscribe((val) => {
      console.log('Pulled the following parts', val)
      ok(val)
    }, err => fail(err));
  });
  // return makeCancelable();
}

@Module
export class PartRequestModule extends VuexModule implements PartRequestState {
  // fetching: boolean = false;

  @Action
  async all(payload: AllPayload) {
    PartRequest.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await partRequestRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      await PartRequest.create({
        data: results.map((data) => {
          if ('info' in data) {
            data['part'] = data['info'];
          }

          return data;
        })
      });

      PartRequest.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      PartRequest.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    // this.context.commit('fetching', true);
    PartRequest.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await partRequestRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await PartRequest.insert({
        data
      });

      PartRequest.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async findByMR(payload: { recordId: number }) {
    PartRequest.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { recordId, ...params } = payload;
      const response = await partRequestRepository.findByMR(recordId, params);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: create toggle? instead of just inserting
      if (data.results) {
        await pullParts({ requests: data.results })
        await PartRequest.insert({
          data: data.results
        });
      }

      PartRequest.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async findByTech(payload: { techId: number }) {
    PartRequest.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { techId, ...params } = payload;

      const response = await partRequestRepository.findByTech(techId, params);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: create toggle? instead of just inserting
      if (data.results) {
        await pullParts({ requests: data.results })
        await PartRequest.insert({
          data: data.results
        });
      }

      PartRequest.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async findByPart(payload: { partId: number }) {
    PartRequest.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await partRequestRepository.findByPart(payload.partId);
      // const response = await partRequestRepository.all({ filter: { part_id: payload.partId } });
      const data = response.data;
      await pullParts({ requests: data.results })
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async cancel(payload: { id: number }) {
    try {
      PartRequest.commit(state => {
        state.saving = true;
        state.error.length = 0;
      });
      // todo: Figure out what the response value is. Api was throwing errors at this time
      const { status } = await partRequestRepository.cancel(payload.id);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      await PartRequest.delete(payload.id)

      PartRequest.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.saving = false;
        state.error = [e];
        console.log('[PR cancel] error push', state);
      });

      // throw e;
    }

  }

  @Action({ rawError: true })
  async toggleFlag(payload: ToggleFlagPayload) {
    try {
      PartRequest.commit(state => {
        state.saving = true;
        state.error.length = 0;
      });
      const { flag } = payload;
      let c: null | ((id: number) => Promise<AxiosResponse<PartRequestInterface>>) = null;

      switch (flag) {
        case 'void':
          c = (id: number) => partRequestRepository.void(id);
          break;
        case 'fulfill':
          c = (id: number) => partRequestRepository.fulfill(id);
          break;
        case 'order':
          c = (id: number) => partRequestRepository.order(id);
          break;
      }

      if (!c) {
        throw `Unknown or undefined flag ${flag}`;
      }

      const { status, data } = await c(payload.id);
      console.log('flag response', data)

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      await PartRequest.insertOrUpdate({
        data: [data]
      });

      PartRequest.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);
      PartRequest.commit(state => {
        state.saving = false;
        state.error = [e];
        console.log('[PR toggleFlag] error push', state);
      });

      // throw e;
    }
  }

  @Action
  async void({ id }: { id: number }) {
    this.context.dispatch('toggleFlag', { id, flag: 'void' });
  }

  @Action
  async fulfill({ id }: { id: number }) {
    this.context.dispatch('toggleFlag', { id, flag: 'fulfill' });
  }

  @Action
  async order({ id }: { id: number }) {
    this.context.dispatch('toggleFlag', { id, flag: 'order' });
  }
}



export default PartRequestModule;
// export default getModule(PartRequestModule);