import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { TaskInterface } from './Task';
import { AllPayload, TaskApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import Technician from '../Technician/Technician';
import TaskSchedule, { TaskScheduleInterface } from '../TaskSchedule/TaskSchedule';
import moment from 'moment';



export class TaskRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('TaskRepository::findOne', id, config);
    return this.request.get<TaskInterface>(`task/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<TaskApiCollection>('list', params ? { ...config, params } : config);
  }

  async today(params: AllPayload = {}, config: AxiosRequestConfig = {}): Promise<TaskInterface[]> {
    const response = await this.request.get('today', params ? { ...config, params } : config);

    const data: Record<string, Array<TaskScheduleInterface & { info: TaskInterface }>> = response.data || [];
    if (!data) {
      return [];
    }

    const schedules = Object.values(data);

    const idx: Record<number, boolean> = {};

    const tasks = schedules.reduce<TaskInterface[]>((acc, val) => {
      const t = val.map<TaskInterface | null>(({ info }) => {
        if (!info) {
          return null;
        }
        const id = +info.ID;
        if (idx[id]) {
          return null;
        }
        idx[id] = true;
        return info;
      }).filter(val => val !== null) as TaskInterface[];

      acc = acc.concat(t)
      return acc;
    }, []);
    return tasks;
  }

  async queued(params: AllPayload = {}, config: AxiosRequestConfig = {}): Promise<TaskInterface[]> {
    const response = await this.request.get('queued', params ? { ...config, params } : config);

    const data: Array<TaskScheduleInterface & { info: TaskInterface }> = response.data || [];
    if (!data) {
      return [];
    }

    return data.map(({ info }) => info);
  }

  async upcoming(params: AllPayload = {}, config: AxiosRequestConfig = {}): Promise<TaskInterface[]> {
    // todo: update this when api supports it, using "schedule grid" for now
    const gridResponse = await this.request.get('scheduleGrid', params ? { ...config, params } : config);

    const grid: Array<Technician & { Tasks: Record<string, Array<TaskScheduleInterface & { info: TaskInterface }>> }> = gridResponse.data || [];
    if (!grid || !grid.length) {
      return [];
    }

    const now = moment().format('YYYY-MM-DD');
    const next = moment().add(1, 'd').format('YYYY-MM-DD');

    const idx: Record<number, boolean> = {};
    return grid.map(({ Tasks }) => {
      /////
      // temp, since Task are indexed by date, if there are no tasks for today/tomorrow then use the earliest date
      // can probably be removed after API has proper support
      const validKeys = Object.keys(Tasks);
      let k: string[] = [now, next];
      if (validKeys.length && validKeys.indexOf(now) === -1 && validKeys.indexOf(next) === -1) {
        k = [validKeys.shift() || ''];
      }
      /////
      const schedules = k.reduce<Array<TaskScheduleInterface & { info: TaskInterface }>>((acc, k) => {
        if (!Tasks[k]) {
          return acc;
        }
        const t = Tasks[k];
        return acc.concat(t);
        // return acc;
      }, [])

      const tasks = schedules.map(({ info }) => {
        const id = +info.ID;
        if (idx[id]) {
          return null;
        }
        idx[id] = true;
        return info;
      });

      return tasks.filter(val => val !== null) as TaskInterface[];
    }).flat();
  }
}

const taskRepository = new TaskRepository(axiosProvider('job', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { taskRepository };