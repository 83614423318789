import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { ManufacturerInterface } from './Manufacturer';
import { AllPayload, ManufacturerApiCollection, ManufacturerAllPayload } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class ManufacturerRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<ManufacturerInterface>(`getMfr/${id}`, config);
  }

  async all(params: ManufacturerAllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<ManufacturerApiCollection>('/getMfr', params ? { ...config, params } : config);
  }

}

const manufacturerRepository = new ManufacturerRepository(axiosProvider('parts', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { manufacturerRepository };