import { RouteConfig } from 'vue-router';

// Maintenance Record (MR) components
const CustomerIndex = () => import(/* webpackChunkName: "customer-group" */ '@/pages/customer/CustomerIndex.vue')
const CustomerDetail = () => import(/* webpackChunkName: "customer-group" */ '@/pages/customer/CustomerDetail.vue')
const CustomerListHandler = () => import(/* webpackChunkName: "customer-group" */ '@/pages/customer/CustomerListHandler.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const routes: RouteConfig = {
  path: '/customer',
  // name: 'Customer',
  component: SlidePage,
  redirect: '/customer/',
  children: [
    {
      path: '/',
      component: CustomerIndex,
      name: 'Customer',
      children: [
        {
          path: '/',
          component: CustomerListHandler,
          name: "Customer Records"
        },
        {
          path: ':id',
          component: CustomerDetail,
          props: true,
          name: "Record Detail"
        }
      ]
    },
    {
      path: '*',
      name: 'Task catch all',
      component: UnderConstruction
    }
  ]
};

export default routes;