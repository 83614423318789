import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { normalizeError } from '@/utility';
import { Timesheet, timesheetRepository, AllPayload, TimesheetAllPayload } from '@/store/modules';

@Module
export class TimesheetModule extends VuexModule {
  @Action
  async findByTech(payload: { techId: number } & TimesheetAllPayload) {
    Timesheet.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    })

    try {
      const { techId, ...filters } = payload;
      const response = await timesheetRepository.findByTech(techId, filters);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = {
        results: response.data,
        total: response.data.length
      };

      // todo handle totals?
      if (data.results) {
        Timesheet.insert({ data: data.results });
      }

      Timesheet.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Timesheet.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async all(payload: TimesheetAllPayload) {
    Timesheet.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await timesheetRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total, hours } = data;

      console.log('timeshee insert', data.results.map(({ tech_name }) => tech_name))
      // Timesheet.deleteAll()

      Timesheet.create({
        data: results.map((task, i) => {
          // vuex-orm does not preserve the sort
          return { ...task, i };
        })
      });

      console.log('timeshee insert AFTER INTERST', Timesheet.query().get())

      Timesheet.commit(state => {
        state.fetching = false;
        state.total = +total;
        state.hours = +(hours || 0);
      });

    } catch (err) {
      console.error(err);
      const e = (normalizeError(err) || err);

      Timesheet.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }
}

export default TimesheetModule;