import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosProvider } from '@/store/provider';
import { SensorInterface } from './Sensor';
import { AllPayload, SensorApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import { filter } from 'rxjs/operators';
import { MetricApiCollection } from '../helper-types';

export interface CreateSensorDto {
  name: string;
  deviceEUI: string;
}

export interface UpdateSensorDto extends CreateSensorDto {
  id: number;
}


export class SensorRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id_: number, config: AxiosRequestConfig = {}): Promise<AxiosResponse<SensorInterface>> {
    // console.log('SensorRepository::findOne', id, config);
    const { data, ...response } = await this.all({}, config);

    const d = (data.results ?? []).filter(({ id }) => id === id_).pop();
    if (!d) {
      throw Error('Not found');
    }
    return { ...response, data: d }
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log('Sensor Request all', params);
    const conf = params ? { ...config, params } : config;
    return this.request.get<SensorApiCollection>('/tektelic-api/devices', conf);
  }

  async create(payload: CreateSensorDto, config: AxiosRequestConfig = {}) {
    return this.request.post<SensorInterface>(
      'setSensor',
      payload,
      config
    );
  }

  async update(payload: UpdateSensorDto, config: AxiosRequestConfig = {}) {
    return this.request.put<SensorInterface>(
      'setSensor',
      payload,
      config
    );
  }

  async getLatestMetric(deviceEUI: string, config: AxiosRequestConfig = {}) {
    return this.request.get<MetricApiCollection>(
      `metrics/${deviceEUI}`,
      config
    );
  }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<SensorApiCollection>(`bymr/${recordId}`, params ? { ...config, params } : config);
  }

  async findByCategory(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<SensorApiCollection>(`getSensors/category/${recordId}`, params ? { ...config, params } : config);
  }
}

const tmpUrl = 'https://device-api.kb-mqtt.etangle.ca';
const sensorRepository = new SensorRepository(axiosProvider('sensors', {
  baseURL: tmpUrl
}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { sensorRepository };