type R<T> = {
  promise: Promise<T>;
  cancel: () => void
}
type F = <T>(promise: Promise<T>) => R<T>

export type MakeCancelable<T> = R<T>;

/**
* @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
* @param promise 
*/
const makeCancelable: F = <T>(promise: Promise<T>) => {
  let hasCanceled_ = false;
  const wrappedPromise = new Promise<T>((resolve, reject) => {
      promise.then(
          val => hasCanceled_ ? reject({ isCanceled: true }) : resolve(val),
          error => hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
      );
  });

  return {
      promise: wrappedPromise,
      cancel() {
          hasCanceled_ = true;
      },
  };
};

export {makeCancelable};