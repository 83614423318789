import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {axiosProvider} from '@/store/provider';
import {AddressInterface, Country, Region } from './Address';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export class AddressRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<AddressInterface & {Country?: Country; Region?: Region}>(`show/${id}`, config);
  }
}

const addressRepository = new AddressRepository(axiosProvider('address', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export {addressRepository};