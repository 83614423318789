import { RouteConfig } from 'vue-router'

// Maintenance Record (MR) components
const SensorIndex = () => import(/* webpackChunkName: "part-group" */ '@/pages/sensor/SensorIndex.vue')
const SensorDetail = () => import(/* webpackChunkName: "part-group" */ '@/pages/sensor/SensorDetail.vue')
const SensorListHandler = () => import(/* webpackChunkName: "part-group" */ '@/pages/sensor/SensorListHandler.vue')
// const SensorAdd = () => import(/* webpackChunkName: "part-group" */ '@/pages/sensor/SensorAdd.vue')
// const SensorEdit = () => import(/* webpackChunkName: "part-group" */ '@/pages/sensor/SensorEdit.vue')

const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const route: RouteConfig[] = [
  {
    path: 'sensor',
    component: SlidePage,
    redirect: '/sensor/',
    children: [
      {
        path: '/',
        component: SensorIndex,
        name: "Sensors",
        children: [{
          path: '/',
          component: SensorListHandler,
          props: true,
          name: "Sensors"
        }, {
          path: ':id',
          component: SensorDetail,
          props: true,
          name: "Sensor Detail"
        }]
      },

      {
        path: '*',
        name: 'Sensor Catchall',
        component: UnderConstruction
      }
    ],



  }


]

export default route;