import { Model } from '@vuex-orm/core';
import Part from '../Part/Part';

export interface PartCategoryInterface {
  id: number;
  name: string;
  parent_id?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  suspended: number;
  parts?: Part[];
}

export class PartCategory extends Model implements PartCategoryInterface {
  id!: number;
  suspended!: number;
  name!: string;
  parent_id?: number | null | undefined;
  created_at?: string | null | undefined;
  updated_at?: string | null | undefined;
  parts?: Part[];
  
  static entity = 'partCategory';
  static primaryKey = 'id'
  
  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      name: this.string(null).nullable(),
      parent_id: this.number(null).nullable(),
      suspended: this.number(0).nullable(),
      parts: this.hasMany(Part, 'category_id')
    }
  }
}

export default PartCategory;