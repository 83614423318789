import { MRState, RootState } from '@/store/types';
import { normalizeError } from '@/utility';
import { AxiosResponse } from 'axios';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, ActionTree } from 'vuex';
import { MRAllPayload } from '../helper-types';
import { taskRepository } from '../Task';
import { MR, MRInterface } from './MR';
import { MRFlag, mrRepository } from './repository';

const defaultAllOptions = {
  replace: true
};

const getOptionsFromPayload = (payload: MRAllPayload) => ({ ...defaultAllOptions, ...(payload.options || {}) })

const fetchData: Action<MRState, RootState> = async function ({ commit }, payload: MRAllPayload = {}): Promise<any> {

  MR.commit(state => {
    state.fetching = true;
    state.error.length = 0;
  });


  return mrRepository.fetchData(payload).then(response => {
    if (response.status < 200 && response.status >= 300) {
      console.error('Invalid 2XX response status');
      throw `Invalid response code: ${response.status}`;
    }

    const options = getOptionsFromPayload(payload);


    const done = (records: MR[], total = 0) => {

      if (options.replace === false) {
        MR.insert({
          data: records
        });
      } else {
        MR.create({
          data: records
        });
      }

      MR.commit(state => {
        state.fetching = false;
        state.total = +total;
      });
    };

    if (!response.data.results || !response.data.results.length) {
      done([]);
      return;
    }

    // console.log('...', response.data.results.map(x=>x.created_at));
    const $source = of<MRInterface[]>(response.data.results).pipe(
      // distinct(mr => mr.taskID),
      mergeMap((mrs) => {
        return forkJoin(...mrs.map(record => {

          if ('Notes' in record) {
            record['notes'] = record['Notes'];
            delete record['Notes'];
          }

          if ('Time' in record) {
            record['time'] = record['Time'];
            delete record['Time'];
          }

          if (!record.taskID) {
            return of(record);
          }

          if (!payload.withJob) {
            return of(record);
          }

          return taskRepository.findOne(record.taskID)
            .then(response => {

              if (response.data) {
                record.task = response.data;
              }

              return record;
            })
            .catch(err => {
              console.warn('e', err);
              return record;
            });
        }));

      })
    );

    $source.subscribe(records => {
      done(records, +response.data.total);
      /*MR.create({
        data: records
      });

      MR.commit(state => {
        state.fetching = false;
        state.total = +response.data.total;
      });*/
    });

  }).catch(err => {
    console.log('found error in MR module');
    console.error(err);
    const e = (normalizeError(err) || err);
    MR.commit(state => {
      state.fetching = false;
      state.total = 0;
      state.error.push(e);
    });

    throw e;
  });
}

const findOne: Action<MRState, RootState> = async ({ commit }, payload: { id: number }) => {
  MR.commit(state => {
    state.fetching = true;
    state.error.length = 0;
  });

  try {
    const response = await mrRepository.findOne(payload.id);

    if (response.status < 200 && response.status >= 300) {
      console.error('Invalid 2XX response status');
      throw `Invalid response code: ${response.status}`;
    }

    const record = response.data;

    if (record.taskID) {
      const tResponse = await taskRepository.findOne(+record.taskID);
      if (tResponse.data) {
        record.task = tResponse.data;

        // if(tResponse.data.jobID) {
        //   await Job.dispatch('findOne', +tResponse.data.jobID);
        //   // await dispatch('entities/job/findOne', +tResponse.data.jobID);
        // }
      }
    }

    if ('Time' in record) {
      record['time'] = record['Time'];
      delete record['Time'];
    }

    if ('Notes' in record) {
      record['notes'] = record['Notes'];
      delete record['Notes'];
    }

    MR.insert({
      data: record
    });

    MR.commit(state => {
      state.fetching = false;
    });

  } catch (err) {
    console.error(err);
    const e = (normalizeError(err) || err);
    MR.commit(state => {
      state.fetching = false;
      state.error.push(e);
    });

    throw e;
  }

}


type ToggleFlagPayload = { id: number; flag: MRFlag };

const toggleFlag: Action<MRState, RootState> = async ({ commit }, payload: ToggleFlagPayload) => {
  try {
    MR.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    const { flag } = payload;
    let c: null | ((id: number) => Promise<AxiosResponse<MRInterface>>) = null;

    switch (flag) {
      case 'approve':
        c = (id: number) => mrRepository.approve(id);
        break;
      case 'complete':
        c = (id: number) => mrRepository.complete(id);
        break;
      case 'void':
        c = (id: number) => mrRepository.void(id);
        break;
      case 'paid':
        c = (id: number) => mrRepository.paid(id);
        break;
    }

    if (!c) {
      throw `Unknown or undefined flag ${flag}`;
    }

    const { status, data } = await c(payload.id);
    console.log('flag response', data)

    if (status < 200 && status >= 300) {
      console.error('Invalid 2XX response status');
      throw `Invalid response code: ${status}`;
    }

    if (!data) {
      console.error('Invalid data status codes');
      throw 'Invalid response payload.'
    }

    await MR.insertOrUpdate({
      data: [data]
    });

    MR.commit(state => {
      state.saving = false;
      state.error.length = 0;
    });

  } catch (err) {
    const e = (normalizeError(err) || err);
    MR.commit(state => {
      state.saving = false;
      state.error.push(e);
      console.log('error push', state);
    });

    throw e;
  }
}

// 'approve' | 'complete' | 'void' | 'paid'
const approve: Action<MRState, RootState> = async (context, {id}: { id: number }) => {
  return context.dispatch('toggleFlag', {id, flag: 'approve'});
}

const complete: Action<MRState, RootState> = async (context, {id}: { id: number }) => {
  return context.dispatch('toggleFlag', {id, flag: 'complete'});
}

// void is reserved keyword
const markVoid: Action<MRState, RootState> = async (context, {id}: { id: number }) => {
  return context.dispatch('toggleFlag', {id, flag: 'void'});
}

const paid: Action<MRState, RootState> = async (context, {id}: { id: number }) => {
  return context.dispatch('toggleFlag', {id, flag: 'paid'});
}

const actions: ActionTree<MRState, RootState> = {
  fetchData: fetchData,
  findOne,
  approve, complete, markVoid, paid,
  toggleFlag
}

export { actions };
