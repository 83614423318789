import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { JobState } from '@/store/types';
import { Job, jobRepository, FindOneActionPayload, WithCustomerActionPayload, WithAddressActionPayload, WithBillingAddressActionPayload, AllPayload } from '@/store/modules';
// import {JobRepository} from './repository';
import { normalizeError } from '@/utility';
import { Customer } from '../Customer';
import { Address } from '../Address';

type FindOnePayload = FindOneActionPayload & WithCustomerActionPayload & WithAddressActionPayload & WithBillingAddressActionPayload;

@Module
export class JobModule extends VuexModule implements JobState {

  // fetching: boolean = false;
  @Action
  async all(payload: AllPayload) {
    Job.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await jobRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      // const fixResults = results.map(result => {
      //   const task = result.info;

      //   return fix(task);
      // });
      await Job.create({
        data: results
      });

      Job.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Job.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async queued(payload: AllPayload) {
    Job.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await jobRepository.queued(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      const results: Job[] = [];

      data.forEach(({ info, taskID }) => {
        if (!info || !info.Address || !info.Job) {
          return;
        }
        results.push({
          ...info.Job,
          queuedTaskID: taskID || null,
          queuedStatus: info.status
        });

        Address.insert({ data: info.Address });

        if (info.Customer) {
          Customer.insert({ data: info.Customer });
        }
      })

      await Job.create({
        data: results
      });

      Job.commit(state => {
        state.fetching = false;
        state.total = +results.length;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Job.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async findOne(payload: FindOnePayload) {
    // this.context.commit('fetching', true);

    Job.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await jobRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: temporarily remove the task information since it isn't needed yet.
      // when needed make sure this maps the data.task <-- lowercase 
      if ('Task' in data) {
        delete data['Task'];
      }

      if (payload.withCustomer && data.customerID) {
        await Customer.dispatch('findOne', {
          id: data.customerID
        });
      }

      if (payload.withAddress && data.addressID) {
        await Address.dispatch('findOne', {
          id: data.addressID
        })
      }

      if (payload.withBillingAddress && data.billingaddressID) {
        await Address.dispatch('findOne', {
          id: data.billingaddressID
        })
      }

      Job.insert({ data });

      Job.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Job.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

}

export default JobModule;
// export default getModule(JobModule);