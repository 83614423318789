import { axiosProvider } from '@/store/provider';
import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class StaffRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async forcePasswordChange(payload: { email: string, password: string, confirmPassword: string }, config: AxiosRequestConfig = {}) {
    return this.request.post<{ ID: number; type: string; refID: number }>('forcechange', {
      email: payload.email,
      passwordA: payload.password,
      passwordB: payload.confirmPassword,
    }, config);
  }
}

const staffRepository = new StaffRepository(axiosProvider('auth', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { staffRepository };
