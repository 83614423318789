import Axios, { AxiosInstance, AxiosRequestConfig, AxiosInterceptorManager, AxiosResponse } from 'axios';
import { AxiosProvider, AxiosInterceptors } from './types';


const axiosProvider: AxiosProvider = (baseURL: string, axiosConfig: AxiosRequestConfig = {}, interceptors?: AxiosInterceptors) => {
  const base = { baseURL };
  const config = { ...base, ...axiosConfig };
  
  const a = Axios.create(config);
  if (interceptors) {
    if (interceptors.request) {
      interceptors.request.forEach(i => a.interceptors.request.use(i.resolve, i.reject));
    }

    if (interceptors.response) {
      interceptors.response.forEach(i => a.interceptors.response.use(i.resolve, i.reject));
    }
  }
  return a;
}

export default axiosProvider;

