import { MR, MRInterface } from '@/store/modules';
import { Model } from '@vuex-orm/core';
import { Technician } from '../Technician';
import moment from 'moment';

export interface TimesheetInterface {
  id: number;
  mrID?: number | null;
  jobID?: number | null;
  taskID?: number | null;

  mr?: MRInterface;
  technician?: Technician | null;
  start?: string | null;
  end?: string | null;
  work_type: string;
  travel?: boolean;
  voided?: boolean;

  techID?: number | null;
  tech_name?: string | null;
  jobNumber?: string | null;
  taskNumber?: string | null;
  // rate
  cost?: number | null;
  rate?: number | null;
}

export class Timesheet extends Model implements TimesheetInterface {
  id!: number;
  work_type!: string;
  mr?: MRInterface;
  travel?: boolean;
  mrID?: number | null;
  start?: string | null;
  end?: string | null;
  techID?: number | null;
  tech_name?: string | null;
  jobNumber?: string | null;
  taskNumber?: string | null;
  technician?: Technician | null;
  jobID?: number | null;
  taskID?: number | null;
  cost?: number | null;
  rate?: number | null;

  static entity = 'timesheet';
  static primaryKey = 'id';

  static state() {
    return {
      total: 0,
      fetching: false,
      error: [],
      hours: 0
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      mrID: this.number(null).nullable(),
      jobID: this.number(null).nullable(),
      taskID: this.number(null).nullable(),
      techID: this.number(null).nullable(),
      tech_name: this.string(null).nullable(),
      start: this.string(null).nullable(),
      end: this.string(null).nullable(),
      travel: this.boolean(null).nullable(),

      work_type: this.string('').nullable(),

      jobNumber: this.string('').nullable(),
      taskNumber: this.string('').nullable(),

      cost: this.number(null).nullable(),
      rate: this.number(null).nullable(),

      voided: this.boolean(false),

      mr: this.belongsTo(MR, 'mrID'),
      technician: this.belongsTo(Technician, 'techID', 'ID'),
      i: this.number(null).nullable(),
    };
  }

  get durationHours() {
    if (!this.start || !this.end) {
      return 0;
    }

    const s = moment(this.start), e = moment(this.end);
    const d = e.diff(s, "hours", true).toFixed(2);
    return d;
  }
}