import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { PaymentInterface } from './Payment';
import { AllPayload, PaymentApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export type PaymentFlag = 'approve' | 'void';

export class PaymentRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('PaymentRepository::findOne', id, config);
    return this.request.get<PaymentInterface>(`byid/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params, 'all');
    return this.request.get<PaymentApiCollection>('list', params ? { ...config, params } : config);
  }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<PaymentApiCollection>(`bymr/${recordId}`, params ? { ...config, params } : config);
  }

  async approve(id: number, config: AxiosRequestConfig = {}) {
    return this.toogleFlag(`approve`, id, config);
  }

  async void(id: number, config: AxiosRequestConfig = {}) {
    return this.toogleFlag(`void`, id, config);
  }

  async toogleFlag(path: PaymentFlag, id: number, config: AxiosRequestConfig = {}) {
    const data = { id: '' + id };
    return this.request.patch<PaymentInterface>(path, data, { ...config });

    // return this.request.patch<PaymentInterface>(`${path}/${id}`, {}, { ...config });
  }
}

const paymentRepository = new PaymentRepository(axiosProvider('payments', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { paymentRepository };