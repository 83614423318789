const env = process.env.NODE_ENV;
import { productionProvider } from './AxiosProductionProvider';
import { devProvider } from './AxiosDevProvider';
import { testProvider } from './AxiosTestProvider';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { AxiosInterceptors } from './types';

let axiosProvider: (serviceKey: string, axiosConfig?: AxiosRequestConfig, otherInterceptors?: AxiosInterceptors) => AxiosInstance;

switch (env) {
    case 'prod':
    case 'production':
        axiosProvider = productionProvider;
        break;
    case 'test':
    case 'testing':
        axiosProvider = testProvider;
        break;
    case 'dev':
    case 'development':
        axiosProvider = devProvider;
        break;
}

export { axiosProvider };
export * from './TokenStorage';
export * from './types';
// export * from './intercept';