import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import VuexORM from '@vuex-orm/core'
import { database } from './database';
import { RootState } from './types';

Vue.use(Vuex);

// See https://codeburst.io/vuex-and-typescript-3427ba78cfa8
const s: StoreOptions<RootState> = {
  state: {
    auth: {},
    mr: {}
  },
  modules: {/*currentPositionModule: CurrentPositionModule*/},
  plugins: [VuexORM.install(database)]
};


const store = new Vuex.Store<RootState>(s);

export default store;
