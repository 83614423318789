import { axiosProvider } from '@/store/provider';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { UserInterface } from './User';



export class UserRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async registered(config: AxiosRequestConfig = {}) {
    // console.log('UserRepository::findOne', id, config);
    return this.request.get<UserInterface>(`registered`, config);
  }
}

const userRepository = new UserRepository(axiosProvider('permission', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { userRepository };
