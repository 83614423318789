import { Model } from '@vuex-orm/core';

export interface CountryInterface {
  ID: number;
  name: string | null;
  acronym: string | null;
}

export class Country extends Model implements CountryInterface {
  ID!: number;
  name!: string | null;
  acronym!: string | null;
  static entity = 'country';
  static primaryKey = 'ID'

  static fields() {
    return {
      ID: this.attr(null),
      name: this.string(null).nullable(),
      acronym: this.string(null).nullable()
    }
  }
}

export interface RegionInterface {
  ID: number,
}

export class Region extends Model implements RegionInterface {
  ID!: number;
  static entity = 'region';
  static primaryKey = 'ID'

  static fields() {
    return {
      ID: this.attr(null),
      countryID: this.number(null).nullable(),
      name: this.string(null).nullable(),
      acronym: this.string(null).nullable()
    }
  }
}

export interface AddressInterface {
  ID: number;
  country?: Country;
  region?: Region;
  countryID: number | null;
  regionID: number | null;
  city: string | null;
  house_number: string | null;
  street_address: string | null;
  ordinant: string | null;
  unit: string | null;
  unit_identifier: string | null;
  postal_code: string | null;
  po_box: string | null;
  addresscol: string | null;
  type: string | null;
  size: string | null;
  full_address: string | null;
}

export class Address extends Model implements AddressInterface {
  ID!: number;

  countryID!: number | null;
  regionID!: number | null;
  city!: string | null;
  house_number!: string | null;
  street_address!: string | null;
  ordinant!: string | null;
  unit!: string | null;
  unit_identifier!: string | null;
  postal_code!: string | null;
  po_box!: string | null;
  addresscol!: string | null;
  type!: string | null;
  size!: string | null;
  full_address!: string | null;

  country?: Country;
  region?: Region;

  static entity = 'address';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),

      countryID: this.number(null).nullable(),
      regionID: this.number(null).nullable(),

      city: this.string(null).nullable(),
      house_number: this.string(null).nullable(),
      street_address: this.string(null).nullable(),
      ordinant: this.string(null).nullable(),
      unit: this.string(null).nullable(),
      unit_identifier: this.string(null).nullable(),
      postal_code: this.string(null).nullable(),
      po_box: this.string(null).nullable(),
      addresscol: this.string(null).nullable(),
      type: this.string(null).nullable(),
      size: this.string(null).nullable(),
      full_address: this.string(null).nullable(),

      country: this.belongsTo(Country, 'countryID', 'ID'),
      region: this.belongsTo(Region, 'regionID', 'ID')
    }
  }
}

export default Address;