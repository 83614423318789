import { RouteConfig } from 'vue-router'

// Maintenance Record (MR) components
const PaymentIndex = () => import(/* webpackChunkName: "payment-group" */ '@/pages/payment/PaymentIndex.vue')
const StripeIndex = () => import(/* webpackChunkName: "payment-group-stripe" */ '@/pages/payment/StripeIndex.vue')
const StripeListHandler = () => import(/* webpackChunkName: "payment-group-stripe" */ '@/pages/payment/StripeListHandler.vue')
const PaymentDetail = () => import(/* webpackChunkName: "payment-group" */ '@/pages/payment/PaymentDetail.vue')
const PaymentListHandler = () => import(/* webpackChunkName: "payment-group" */ '@/pages/payment/PaymentListHandler.vue')
const PaymentPrintIndex = () => import(/* webpackChunkName: "payment-group" */ '@/pages/payment/PaymentPrintIndex.vue')
const PaymentReceiptPrint = () => import(/* webpackChunkName: "payment-group" */ '@/pages/payment/print/PaymentReceiptPrint.vue')


const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const route: RouteConfig = {
  path: 'payment',
  // name: 'Maintenance Records',
  component: SlidePage,
  children: [
    {
      path: 'stripe',
      component: StripeIndex,
      name: "Stripe",
      children: [{
        path: '/',
        component: StripeListHandler,
        name: "Payment"
      }]
    },
    {
      path: '/',
      component: PaymentIndex,
      name: "Payment",
      children: [{
        path: '/',
        component: PaymentListHandler,
        name: "Payment"
      }, {
        path: ':id',
        component: PaymentDetail,
        props: true,
        name: "Payment Detail"
      }]
    },
    {
      path: 'print',
      component: PaymentPrintIndex,
      name: "Payment Print",
      children: [{
        path: ':id',
        component: PaymentReceiptPrint,
        props: true,
        name: "Payment print receipt"
      }]
    },
    {
      path: '*',
      name: 'Payment',
      component: UnderConstruction
    }
  ]
}

export default route;