import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { MRNoteInterface } from './MRNote';
import { AllPayload, MRNoteApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';


// NOTE! No api calls has been implemented yet. Just laying out hte class
export class MRNoteRepository implements RepositoryInterface {
  request: AxiosInstance;


  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('MRNoteRepository::findOne', id, config);
    return this.request.get<MRNoteInterface>(`show/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<MRNoteApiCollection>('list', params ? { ...config, params } : config);
  }
}

const mrNoteRepository = new MRNoteRepository(axiosProvider('mr', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { mrNoteRepository };