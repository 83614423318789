import { TokenStorageInterface } from "./interface";

export class TokenStorage<T = any> implements TokenStorageInterface {

  name: string;

  storage: Storage;

  constructor(name: string, storage: Storage) {
    this.name = name;
    this.storage = storage;
  }

  async removeItem(): Promise<void> {
    this.storage.removeItem(this.name);
  }

  async getItem<T>(): Promise<T | null> {
    const item = this.storage.getItem(this.name);
    if (!item) {
      return null;
    }

    return JSON.parse(item);
  }

  async setItem<T>(auth: T): Promise<void> {
    let s = '';
    if (typeof auth === 'object') {
      s = JSON.stringify(auth);
    } else {
      s = `${auth}`;
    }
    this.storage.setItem(this.name, s);
  }

  getStorage(): Storage {
    return this.storage;
  }
}

const version = process.env.VUE_APP_VERSION || '0';

export const tokenStorage = new TokenStorage(`__AUTH_ACCESS_TOKEN_${version}__`, window.localStorage);