import { AllPayload, TaskSchedule } from '@/store/modules';
import { TaskScheduleState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { taskScheduleRepository } from './repository';

@Module
export class TaskScheduleModule extends VuexModule implements TaskScheduleState {
  // fetching: boolean = false;
  @Action
  async techSchedule(payload: AllPayload & { techId: number }) {
    TaskSchedule.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await taskScheduleRepository.findByTech(payload.techId, payload);
      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      await TaskSchedule.create({
        data: results
      });

       TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async customerSchedule(payload: AllPayload & { customerID: number }) {
    TaskSchedule.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await taskScheduleRepository.findByCustomer(payload.customerID);
      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      await TaskSchedule.create({
        data: results
      });

       TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }

  @Action
  async all(payload: AllPayload) {
    TaskSchedule.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await taskScheduleRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      await TaskSchedule.create({
        data: results
      });

       TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      TaskSchedule.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    // this.context.commit('fetching', true);
    TaskSchedule.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await taskScheduleRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await TaskSchedule.insert({
        data
      });

      TaskSchedule.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      TaskSchedule.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

}

export default TaskScheduleModule;
// export default getModule(TaskScheduleModule);