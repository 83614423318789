import { Model } from '@vuex-orm/core';
import { MR, Address } from '@/store/modules';
import { Job } from '../Job';
import { Customer } from '../Customer';
import TaskSchedule from '../TaskSchedule/TaskSchedule';

export interface TaskInterface {
  ID: number;
  jobID?: number;
  job?: Job | null;
  description: string | null;
  // Customer?: Customer | null;
  // Address?: Address | null;

  worktypeID: number | null;
  total_price: number | null;
  service_task: number | null;
  status: string | null;
  datetime: string | null;
  assigneduserID: number | null;
  taskIndex: number | null;
  priority: number | null;
  warrantystart: string | null;
  taskNumber: string | null;
  ponumber: string | null;
  mr?: MR[];

  schedule?: TaskSchedule[];
  // address?: Address | null;
}

export class Task extends Model implements TaskInterface {
  ID!: number;
  job!: Job | null;
  // Customer!: Customer | null;
  // Address!: Address | null;
  description!: string | null;

  worktypeID!: number | null;
  total_price!: number | null;
  service_task!: number | null;
  status!: string | null;
  datetime!: string | null;
  assigneduserID!: number | null;
  taskIndex!: number | null;
  priority!: number | null;
  warrantystart!: string | null;
  taskNumber!: string | null;
  ponumber!: string | null;
  mr?: MR[];
  schedule?: TaskSchedule[];
  // address?: Address;
  
  static entity = 'task';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      jobID: this.number(null).nullable(),
      worktypeID: this.number(null).nullable(),
      userID: this.number(null).nullable(),
      total_price: this.number(null).nullable(),
      service_task: this.number(null).nullable(),
      status: this.string('').nullable(),
      datetime: this.string(null).nullable(),
      assigneduserID: this.string('').nullable(),
      description: this.string(null).nullable(),
      taskIndex: this.number(0).nullable(),
      priority: this.number(0).nullable(),
      warrantystart: this.string(null).nullable(),
      taskNumber: this.string(null).nullable(),
      ponumber: this.string('').nullable(),
      mr: this.hasMany(MR, 'taskID'),
      job: this.belongsTo(Job, 'jobID', 'ID'),
      schedule: this.hasMany(TaskSchedule, 'taskID'),
      // customerID: this.number(null).nullable(),
      // Customer: this.belongsTo(Customer, 'customerID', 'ID'),
      // addressID: this.number(null).nullable(),
      // Address: this.belongsTo(Address, 'addressID', 'ID')
    }
  }
}

export default Task;