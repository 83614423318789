import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { PartCategoryInterface } from './PartCategory';
import { AllPayload, PartCategoryApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
export interface CreatePartCategoryDto {
  name: string;
}

export interface UpdatePartCategoryDto extends CreatePartCategoryDto {
  id: number;
}
export class PartCategoryRepository implements RepositoryInterface {
  request: AxiosInstance;
  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('PartCategoryRepository::findOne', id, config);
    return this.request.get<PartCategoryInterface>(`getCategory/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartCategoryApiCollection>('getCategories', params ? { ...config, params } : config);
  }

  async create(payload: CreatePartCategoryDto, config: AxiosRequestConfig = {}) {
    return this.request.post<PartCategoryInterface>(
      'setCategory',
      payload,
      config
    );
  }

  async update(payload: UpdatePartCategoryDto, config: AxiosRequestConfig = {}) {
    return this.request.put<null | void | string>(
      'setCategory',
      payload,
      config
    );
  }

  async suspend(id: number, config: AxiosRequestConfig = {}) {
    return this.request.delete<null | void | string>(
      `suspendPartCategory/${id}`,
      config
    );
  }

  async unsuspend(id: number, config: AxiosRequestConfig = {}) {
    return this.request.put<null | void | string>(
      `unSuspendPartCategory/${id}`,
      {},
      config
    );
  }

}

const partCategoryRepository = new PartCategoryRepository(axiosProvider('parts', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { partCategoryRepository };