import { Model } from '@vuex-orm/core';

export interface ManufacturerInterface {
  /**
   * Api has manufacturer as the name, the module normalizes to name after requesting it.
   */
  name?: string | null;
}

export class Manufacturer extends Model implements ManufacturerInterface {

  name?: string | null;


  static entity = 'manufacturer';
  static primaryKey = 'name';

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      name: this.string('')
    };
  }
}