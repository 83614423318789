import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { PartInterface } from './Part';
import { AllPayload, PartApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export interface CreatePartDto {
  name: string;
  part_number: string;
  manufacturer: string | null;
  description?: string | null;
  manual_price?: number;
  public?: boolean;
  category_id?: number | null;
  url?: string | null;
}

export interface UpdatePartDto extends CreatePartDto {
  id: number;
}

export class PartRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('PartRepository::findOne', id, config);
    return this.request.get<PartInterface>(`getPart/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log('Part Request all', params);
    return this.request.get<PartApiCollection>('list', params ? { ...config, params } : config);
  }

  async create(payload: CreatePartDto, config: AxiosRequestConfig = {}) {
    return this.request.post<PartInterface>(
      'setPart',
      payload,
      config
    );
  }

  async update(payload: UpdatePartDto, config: AxiosRequestConfig = {}) {
    return this.request.put<PartInterface>(
      'setPart',
      payload,
      config
    );
  }

  async remove(id: number, config: AxiosRequestConfig = {}) {
    return this.request.delete<PartInterface>(
      `suspendPart/${id}`,
      config
    );
  }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<PartApiCollection>(`bymr/${recordId}`, params ? { ...config, params } : config);
  }

  async findByCategory(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartApiCollection>(`getParts/category/${recordId}`, params ? { ...config, params } : config);
  }
}

const partRepository = new PartRepository(axiosProvider('parts', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { partRepository };