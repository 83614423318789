import { AllPayload, LabourRateApiCollection } from '@/store/modules';
import { axiosProvider } from '@/store/provider';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { LabourRateInterface } from './LabourRate';

export interface CreateLabourRateDto {
  effective_at?: string;
  rate?: number;
  comment?: string;

  created_at?: string;
  voided_comment?: string | null;
  voided_at?: string | null;

  techID?: number;
  tech_name?: string;
}

export interface UpdateLabourRateDto extends CreateLabourRateDto {
  id: number;
}

export class LabourRateRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async current(config: AxiosRequestConfig = {}) {
    // console.log('LabourRateRepository::findOne', id, config);
    return this.request.get<LabourRateInterface>(`rate`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log('LabourRate Request all', params);
    return this.request.get<LabourRateApiCollection>('ratehistory', params ? { ...config, params } : config);
  }

  async create(payload: CreateLabourRateDto, config: AxiosRequestConfig = {}) {
    return this.request.post<LabourRateInterface>(
      'rate',
      payload,
      config
    );
  }

  async void(payload: { id: number, voided_comment?: string }, config: AxiosRequestConfig = {}) {
    return this.request.post<LabourRateInterface>(
      'voidrate',
      payload,
      config
    );
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('LabourRateRepository::findOne', id, config);
    return this.request.get<LabourRateInterface>(`getLabourRate/${id}`, config);
  }





  async update(payload: UpdateLabourRateDto, config: AxiosRequestConfig = {}) {
    return this.request.put<LabourRateInterface>(
      'setLabourRate',
      payload,
      config
    );
  }

  async remove(id: number, config: AxiosRequestConfig = {}) {
    return this.request.delete<LabourRateInterface>(
      `suspendLabourRate/${id}`,
      config
    );
  }
}

const labourRateRepository = new LabourRateRepository(axiosProvider('mr', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { labourRateRepository };
