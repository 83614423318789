import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { PartRequestInterface } from './PartRequest';
import { AllPayload, PartRequestApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export type PartRequestFlag = 'void' | 'fulfill'| 'order';

export class PartRequestRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('PartRequestRepository::findOne', id, config);
    return this.request.get<PartRequestInterface>(`byid/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartRequestApiCollection>('list/requests', params ? { ...config, params } : config);
  }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<PartRequestApiCollection>(`getRequestByMR/${recordId}`, params ? { ...config, params } : config);
  }

  async findByTech(techId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartRequestApiCollection>(`getRequestByTech/${techId}`, params ? { ...config, params } : config);
  }

  async findByPart(partId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartRequestApiCollection>(`getRequested/${partId}`, params ? { ...config, params } : config);
  }

  async void(id: number, config: AxiosRequestConfig = {}) {
    return this.toogleFlag(`void`, id, config);
  }

  async toogleFlag(path: PartRequestFlag, id: number, config: AxiosRequestConfig = {}) {
    const data = { id: '' + id };
    return this.request.patch<PartRequestInterface>(path, data, { ...config });

    // return this.request.patch<PaymentInterface>(`${path}/${id}`, {}, { ...config });
  }

  async cancel(id: number, config: AxiosRequestConfig = {}) {
    return this.request.delete<void>(`/cancelRequest/${id}`, { ...config });
  }

  async fulfill(id: number, config: AxiosRequestConfig = {}) {
    return this.request.patch<PartRequestInterface>(`/request/fulfill`, { id }, { ...config });
  }

  async order(id: number, config: AxiosRequestConfig = {}) {
    return this.request.patch<PartRequestInterface>(`/request/order`, { id }, { ...config });
  }
}

const partRequestRepository = new PartRequestRepository(axiosProvider('parts', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { partRequestRepository };