import { AnyObject } from './types';

const queryFilterHasChange = <T extends AnyObject>(val: T, cmpVal: T): boolean => {

  const k1 = Object.keys(val),
    k2 = Object.keys(cmpVal),
    s: string[] = k1.length >= k2.length ? k1 : k2;
  // console.log('queryFilterHasChange', val, cmpVal);
  for (let i = 0; i < s.length; i++) {
    const k = s[i];
    const v1 = val[k];
    const v2 = cmpVal[k];
    if (v1 !== v2) {
      return true;
    }
  }
  return false;
}

const filterEmptyQuery = <T extends AnyObject>(query: T) => {
  const obj: AnyObject = {};
  for (let k in query) {
    const v = query[k];
    if (v === '' || null === v || (typeof v === 'undefined')) {
      continue;
    }

    obj[k] = v;
  }

  return obj;
};

export { queryFilterHasChange, filterEmptyQuery };