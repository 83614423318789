
import { Component, Vue } from "vue-property-decorator";
import AuthModule from "@/store/modules/Auth/AuthModule";

@Component
export default class App extends Vue {
  init = false;

  async mounted() {
    await AuthModule.loadStorage();
    this.$nextTick(() => {
      this.init = true;
    });
  }
}
