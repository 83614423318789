import { AllPayload, PartCategory } from '@/store/modules';
import { PartCategoryState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { partCategoryRepository, CreatePartCategoryDto, UpdatePartCategoryDto } from './repository';
import { PartCategoryInterface } from './PartCategory';

@Module
export class PartCategoryModule extends VuexModule implements PartCategoryState {
  // fetching: boolean = false;

  @Action
  async all(payload: AllPayload) {
    PartCategory.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await partCategoryRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;

      PartCategory.create({
        data: results
      });

      PartCategory.commit(state => {
        state.fetching = false;
        // state.total = typeof total === 'undefined' ? results.length : +total;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      PartCategory.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    // this.context.commit('fetching', true);
    PartCategory.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await partCategoryRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      PartCategory.insert({
        data
      });

      PartCategory.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      PartCategory.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async createPartCategory(payload: { partCategory: PartCategoryInterface }) {
    const { partCategory: part } = payload;
    const dto: CreatePartCategoryDto = {
      name: part.name
    };

    PartCategory.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });
    try {
      const response = await partCategoryRepository.create(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      await PartCategory.insert({ data });

      console.log('create  complete');

    } catch (err) {
      const e = (normalizeError(err) || err);

      PartCategory.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async updatePartCategory(payload: { partCategory: PartCategoryInterface }) {
    const { partCategory: part } = payload;



    PartCategory.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });
    try {

      if (!part.id) {
        throw 'Missing part id';
        return;
      }

      const dto: UpdatePartCategoryDto = {
        id: part.id,
        name: part.name
      };

      const response = await partCategoryRepository.update(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      // await Part.insert({data});

      console.log('put  complete');

    } catch (err) {
      const e = (normalizeError(err) || err);

      PartCategory.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }



  @Action
  async suspend(payload: { id: number }) {
    const { id } = payload;

    PartCategory.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {

      const response = await partCategoryRepository.suspend(id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

    } catch (err) {
      const e = (normalizeError(err) || err);

      PartCategory.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }
  }
}

export default PartCategoryModule;
// export default getModule(PartCategoryModule);