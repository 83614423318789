// import store from '@/store';
import AuthModule from '@/store/modules/Auth/AuthModule';
import Vue from 'vue';
import Router, { NavigationGuard, RouteConfig } from 'vue-router';
import { default as locationRoute } from './locations';
import { default as mrRoute } from './mr';
import { default as partRoute } from './part';
import { default as paymentRoute } from './payment';
import { default as sensorRoute } from './sensor';
import { default as taskRoute } from './task';
import { default as techRoute } from './technician';
import { default as timesheetRoute } from './timesheet';
import { default as customerRoute } from './customer';
import { default as labourRateRoute } from './labour-rate';
// import {AUTH_CHECK_LOGIN} from '@/store/modules';

const AppContainer = () => import("../containers/AppContainer.vue");
const Login = () => import('@/pages/Login.vue')
const ForgetPasswordPage = () => import('@/pages/auth/ForgetPasswordPage.vue')
const VerifyForgetPasswordPage = () => import('@/pages/auth/VerifyForgetPasswordPage.vue')

const Page404 = () => import('@/views/pages/Page404.vue')
const Page500 = () => import('@/views/pages/Page500.vue')
// const Register = () => import('@/views/pages/Register.vue')
const UnderConstruction = () => import('@/pages/UnderConstruction.vue');

// Views
const Dashboard = () => import('@/pages/Dashboard.vue')

// Pages
const BasePage = () => import('@/pages/BasePage.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')


Vue.use(Router)




// import {default as jobRoute} from './job';
function configRoutes(): RouteConfig[] {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: AppContainer,
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        mrRoute,
        taskRoute,
        timesheetRoute,
        techRoute,
        customerRoute,
        paymentRoute,
        locationRoute,
        ...partRoute,
        ...sensorRoute,
        labourRateRoute,
        // jobRoute
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { requiresAuth: false }
    },
    {
      path: '/forget-password',
      name: 'ForgetPasswordPage',
      component: ForgetPasswordPage,
      meta: { requiresAuth: false }
    },
    {
      path: '/forget-password/verify',
      name: 'VerifyForgetPasswordPage',
      component: VerifyForgetPasswordPage,
      meta: { requiresAuth: false }
    },
    {
      path: '/logout',
      name: 'Logout',
      meta: { requiresAuth: false },
      beforeEnter: async (to, from, next) => {
        await AuthModule.logOut();
        next('/login');
      },
      // redirect: {name: 'Login'}
    },
    {
      path: '*',
      redirect: '/pages/404',
      name: 'Pages',
      component: AppContainer,
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        }
      ]
    }
  ]
}

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes: configRoutes()
})

const navGuard: NavigationGuard = async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      // alert('x')
      await AuthModule.checkLogin();
      // await store.dispatch(AUTH_CHECK_LOGIN);
      next();
    } catch (e) {
      console.error('[nav-guard]', e);
      next({
        path: "/login",
        query: {
          redirect: to.fullPath
        }
      });
      return;
    }
  }
  next();
};


router.beforeResolve(navGuard);


export default router;