import { Module } from "vuex";
import { RootState, MRState } from '../../types';
import {actions} from './actions';
import {mutations} from './mutations';
import {getters} from './getters';

export const state: MRState = {};
const namespaced: boolean = true;

const MRModule: Module<MRState, RootState> = {
    state,
    actions, 
    mutations,
    getters
};

export {MRModule};