import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { PartSoldInterface } from './PartSold';
import { AllPayload, PartSoldApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class PartSoldRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('PartSoldRepository::findOne', id, config);
    return this.request.get<PartSoldInterface>(`byid/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<PartSoldApiCollection>('list', params ? { ...config, params } : config);
  }

  async findByPart(partId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<PartSoldApiCollection>(`getSold/${partId}`, params ? { ...config, params } : config);
  }

  async findByMR(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<PartSoldApiCollection>(`getSoldByMR/${recordId}`, params ? { ...config, params } : config);
  }
}

const partSoldRepository = new PartSoldRepository(axiosProvider('parts', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { partSoldRepository };