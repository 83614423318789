import { Model } from '@vuex-orm/core';
import { AnyObject } from '../../../utility/types';
import { AddressInterface } from '../Address/Address';
import Address from '../Address/Address';

export enum HomeSensorMeasurements {
  BATTERY = 'bat',
  TEMPERATURE = 'temp',
  HUMIDITY = 'hum',
  MOISTURE = 'moist',
  MCU_TEMPERATURE = 'mcu_temp',
  LIGHT_DETECTED = 'lum_d',
  REED_STATE = 'reed',
  REED_COUNT = 'reed_c',
}

export type MeasurementKey = HomeSensorMeasurements.BATTERY | 
  HomeSensorMeasurements.TEMPERATURE | 
  HomeSensorMeasurements.HUMIDITY | 
  HomeSensorMeasurements.MOISTURE | 
  HomeSensorMeasurements.MCU_TEMPERATURE |
  HomeSensorMeasurements.LIGHT_DETECTED |
  HomeSensorMeasurements.REED_COUNT |
  HomeSensorMeasurements.REED_STATE

export interface MetricInterface {
  start: string;
  stop: string;
  time: string;
  val: number | string | boolean;
  measurement_key: MeasurementKey;
  deviceEUI: string;
}

export interface SensorInterface {
  id: number;
  name: string;
  deviceEUI: string;
  metric?: Array<MetricInterface>;
  description: string | null;
  provisioner: 'tektelic' | 'unknown';
  providerPayload?: ({
    tektelic?: AnyObject;
  } & AnyObject) | null;
  address?: AddressInterface | null;
  address_id?: number | null;
}

export class Sensor extends Model implements SensorInterface {
  id!: number;
  name!: string;
  deviceEUI!: string;
  description!: string;
  provisioner!: 'tektelic' | 'unknown';
  providerPayload!: null;

  metric?: Array<MetricInterface>;


  
  address?: AddressInterface;
  address_id?: number | null;

  // providerPayload?: AnyObject;

  static entity = 'sensor';

  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: [],
      saving: false,
      lastInsertedId: -1
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      // created_at: this.string(null).nullable(),
      // updated_at: this.string(null).nullable(),
      name: this.string(null).nullable(),
      deviceEUI: this.string(null).nullable(),
      description: this.string(null).nullable(),
      provisioner: this.string('unknown'),
      metric: this.attr(null),
      providerPayload: this.attr(null),


      ///////////////address///////////////////
      address_id: this.number(null).nullable(),
      address: this.belongsTo(Address, 'address_id', 'ID'),
    }
  }
}

export default Sensor;