import { RouteConfig } from 'vue-router'

// Maintenance Record (MR) components
const MRIndex = () => import(/* webpackChunkName: "mr-group" */ '@/pages/mr/MRIndex.vue')
const MRDetail = () => import(/* webpackChunkName: "mr-group" */ '@/pages/mr/MRDetail.vue')
const MRListOnHold = () => import(/* webpackChunkName: "mr-group" */ '@/pages/mr/MRListOnHold.vue')
const MRPrint = () => import(/* webpackChunkName: "mr-group" */ '@/pages/mr/MRPrint.vue')
const MRListPage = () => import(/* webpackChunkName: "mr-group" */ '@/pages/mr/MRListPage.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const route: RouteConfig = {
  path: 'mr',
  // name: 'Maintenance Records',
  component: SlidePage,
  redirect: '/mr/',
  children: [
    {
      path: '/',
      component: MRIndex,
      name: "Maintenance Records",
      children: [{
        path: '/',
        component: MRListPage,
        name: "Maintenance Records"
      }, {
        path: 'on-hold',
        component: MRListOnHold,
        name: "Maintenance Records"
      }, {
        path: ':id/print',
        component: MRPrint,
        props: true,
        name: "Record Print"
      }, {
        path: ':id',
        component: MRDetail,
        props: true,
        name: "Record Detail"
      }]
    },
    {
      path: '*',
      name: 'MR',
      component: UnderConstruction
    }
  ]
}

export default route;