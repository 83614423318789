import { Model } from '@vuex-orm/core';
import { PartCategory } from '../PartCategory';

export interface PartInterface {
  id: number;
  name: string;
  manufacturer: string | null;
  description?: string | null;
  image?: string | null;
  url?: string | null;
  current_price_id?: number | null;
  manual_price?: number;
  public?: boolean;
  inventory?: number;
  unfulfilled?: number;
  category_id?: number | null;
  created_at?: string | null;
  updated_at?: string | null;
  part_number: string | null;
  category?: PartCategory[];
  on_order?: number;
}

export class Part extends Model implements PartInterface {
  id!: number;
  name!: string;
  manufacturer!: string | null;
  part_number!: string | null;
  description?: string | null | undefined;
  image?: string | null | undefined;
  url?: string | null | undefined;
  current_price_id?: number | null | undefined;
  manual_price?: number | undefined;
  public?: boolean | undefined;
  inventory?: number | undefined;
  unfulfilled?: number | undefined;
  category_id?: number | null | undefined;
  created_at?: string | null | undefined;
  updated_at?: string | null | undefined;
  on_order?: number | undefined;
  // category?: string | null | undefined;
  category?: PartCategory[];
  
  static entity = 'part';
  
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: [],
      saving: false,
      lastInsertedId: -1
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),
      name: this.string(null).nullable(),
      manufacturer: this.string(null).nullable(),
      description: this.string(null).nullable(),
      part_number: this.string(null).nullable(),
      image: this.string(null).nullable(),
      url: this.string(null).nullable(),
      // category: this.string(null).nullable(),
      current_price_id: this.number(null).nullable(),
      manual_price: this.number(null).nullable(),
      category_id: this.number(null).nullable(),
      public: this.boolean(false),
      inventory: this.number(null).nullable(),
      unfulfilled: this.number(null).nullable(),
      on_order: this.number(null).nullable(),
      category: this.belongsTo(PartCategory, 'category_id', 'id')
    }
  }
}

export default Part;