import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { TechnicianInterface } from './Technician';
import { AllPayload, TechnicianApiCollection } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class TechnicianRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('TechnicianRepository::findOne', id, config);
    return this.request.get<TechnicianInterface>(`show/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<TechnicianApiCollection>('list', params ? { ...config, params } : config);
  }

  async updateCognitoId(techId: string | number, cogId: string, config: AxiosRequestConfig = {}) {
    return this.request.patch<{ ID: number; type: string; refID: number }>('changeJWT', {
      ID: techId,
      token: cogId
    }, config);
  }
}

const technicianRepository = new TechnicianRepository(axiosProvider('permission', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { technicianRepository };