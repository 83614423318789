
import { axiosProvider } from '@/store/provider';
import { ServiceAbstract } from '../services';
import { LoginCredentialsDto, LogoutCredentialsDto, RefreshDto, ForgotPasswordDto, ChangePasswordDto } from './dto';
import { AxiosRequestConfig } from 'axios';
import { ConfirmPasswordResetDto } from './dto/ConfirmPasswordReset.dto';
import { AuthResponsePayload, AuthRegisterResponse } from './types';
import { RegisterAwsDto } from './dto/RegisterAws.dto';


export class AuthService extends ServiceAbstract {
  async login(dto: LoginCredentialsDto, config: AxiosRequestConfig = {}) {
    return this.request.post<AuthResponsePayload>(`login`, dto, config);
  }

  async logout(dto: LogoutCredentialsDto, config: AxiosRequestConfig = {}) {
    return this.request.post<AuthResponsePayload>(`logout`, dto, config);
  }

  async refresh(dto: RefreshDto, config: AxiosRequestConfig = {}) {
    return this.request.post<AuthResponsePayload>(`refresh`, dto, config);
  }

  async forgetPassword(dto: ForgotPasswordDto, config: AxiosRequestConfig = {}) {
    return this.request.post<{ status: string }>(`reset`, dto, config);
  }

  async verifyForgetPassword(dto: ConfirmPasswordResetDto, config: AxiosRequestConfig = {}) {
    return this.request.post<{ status: string }>(`reset/verify`, dto, config);
  }

  // async updateAccount(dto: AccountUpdateDto, config: AxiosRequestConfig = {}) {
  //   return this.request.patch<AccountUpdateDto>(`account`, dto, config);
  // }

  async changePassword(dto: ChangePasswordDto, config: AxiosRequestConfig = {}) {
    // ?AuthChallengeResponse
    return this.request.patch<any | null | undefined>(`change-password`, dto, config);
  }

  async checkLogin() {
    return this.request.get('/');
  }

  async register({ email, password }: RegisterAwsDto) {
    return this.request.post<AuthRegisterResponse>('register', { email, password });
  }
}

export const authService = new AuthService(axiosProvider('auth'));