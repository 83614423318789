import {  RequestInterceptors } from '../types';
import { AxiosRequestConfig } from 'axios';
import { authTokenStorage } from '../../../auth/AuthTokenStorage';


export const authTokenInterceptor: RequestInterceptors<AxiosRequestConfig> = {
    resolve: async (config) => {
        const token = await authTokenStorage.getAccessToken();
        if(token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }
}