import { RefreshTokenInterceptorOptions } from '@/store/provider';
import route from '@/router';

const authService = () => import(/* webpackChunkName: "refresh-provider" */'./AuthService').then(m => (m.authService));
const authTokenStorage = () => import(/* webpackChunkName: "refresh-provider" */'./AuthTokenStorage').then(m => (m.authTokenStorage));

export const refreshOptionsProvider: () => Promise<RefreshTokenInterceptorOptions> = async () => {

  const service = await authService();
  const tokenStorage = await authTokenStorage();

  return ({
    handleLogout: async () => {
      // console.log('todo: handle logouts')
      route.push('/logout')
      // store.dispatch(authActions.authLogoutAsync.request());
    },
    handleRefresh: async (token: string) => {
      const refreshResponse = await service.refresh({ token });

      if (!refreshResponse || refreshResponse.status !== 200 || !refreshResponse.data || !refreshResponse.data.token) {
        throw `Invalid refresh response ${refreshResponse.status}`;
      }

      const o = await tokenStorage.getAuth();
      await tokenStorage.saveAuth({ ...o, ...refreshResponse.data });

      return refreshResponse;
    },
    getRefreshToken: async () => {
      return tokenStorage.getRefreshToken();
    },
    getAccessToken: async () => {
      return tokenStorage.getAccessToken();
    }
  } as RefreshTokenInterceptorOptions);
};