import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {axiosProvider} from '@/store/provider';
import { CustomerInterface } from './Customer';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import { AllPayload, CustomerApiCollection } from '@/store/modules';

export class CustomerRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<CustomerInterface>(`${id}/contact/address`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<CustomerApiCollection>('list', params ? { ...config, params } : config);
  }
}

const customerRepository = new CustomerRepository(axiosProvider('customer', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export {customerRepository};