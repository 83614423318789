import { Model } from '@vuex-orm/core';
import { Task } from '@/store/modules';
import { Address } from '../Address';

export interface CustomerAddressInterface {
  ID: number;
  customerID: number;
  addressID?: number;
}

export interface CustomerInterface {
  ID: number;
  ar_number: string | null;
  addresses: CustomerAddressInterface[];
  first_name: string | null;
  last_name: string | null;
  business_name: string | null;
  phone: string | null;
  phone_ext: string | null;
  phone2: string | null;
  phone2_ext: string | null;
  mobile: string | null;
  fax: string | null;
  email: string | null;
  birth_date: string | null;
  salutation: string | null;
  cust_flag: string | null;
  tax_exempt: string | null;
}

export class CustomerAddress extends Model implements CustomerAddressInterface {
  ID!: number;
  customerID!: number;


  static entity = 'customerAddresses';
  static primaryKey = 'ID'

  static fields() {
    return {
      ID: this.attr(null),
      name: this.string(null).nullable(),
      date: this.string(null).nullable(),
      customerID: this.number(null).nullable(),
      addressID: this.number(null).nullable(),
      address: this.belongsTo(Address, 'addressID', 'ID')
    }
  }

}

export class Customer extends Model implements CustomerInterface {
  ID!: number;
  ar_number!: string | null;
  addresses!: [];

  first_name!: string | null;
  last_name!: string | null;
  business_name!: string | null;
  phone!: string | null;
  phone_ext!: string | null;
  phone2!: string | null;
  phone2_ext!: string | null;
  mobile!: string | null;
  fax!: string | null;
  email!: string | null;
  birth_date!: string | null;
  salutation!: string | null;
  cust_flag!: string | null;
  tax_exempt!: string | null;

  static entity = 'customer';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      ar_number: this.string(null).nullable(),
      business_name: this.string(null).nullable(),
      first_name: this.string(null).nullable(),
      last_name: this.string(null).nullable(),
      phone: this.string(null).nullable(),
      phone_ext: this.string(null).nullable(),
      phone2: this.string(null).nullable(),
      phone2_ext: this.string(null).nullable(),
      mobile: this.string(null).nullable(),
      fax: this.string(null).nullable(),
      email: this.string(null).nullable(),
      birth_date: this.string(null).nullable(),
      salutation: this.string(null).nullable(),
      cust_flag: this.string(null).nullable(),
      tax_exempt: this.boolean(false),

      addresses: this.hasMany(CustomerAddress, 'customerID', 'ID')
    }
  }
}

export default Customer;