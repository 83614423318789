import { AllPayload, Part, partRepository, CreatePartDto, PartInterface, UpdatePartDto, PartAllPayload } from '@/store/modules';
import { PartState } from '@/store/types';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

@Module
export class PartModule extends VuexModule implements PartState {
  // fetching: boolean = false;
  @Action
  async byCategory(payload: { recordId: number } & AllPayload) {
    Part.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await partRepository.findByCategory(payload.recordId);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      let { total, results } = data;
      if (typeof total === 'undefined') {
        total = results.length;
      }

      if (payload.limit && results.length > payload.limit) {
        const offset = payload.offset || 0;
        results = results.splice(offset, payload.limit);
      }

      await Part.create({
        data: results
      });

      Part.commit(state => {
        state.fetching = false;
        state.total = +total;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Part.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
    }
  }


  @Action({ rawError: true })
  async all(payload: PartAllPayload) {
    Part.commit(state => {
      state.fetching = true;
      state.error.length = 0;
      state.data = [];
    });


    try {
      const { status, data } = await partRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { total, results } = data;
      // let {total} = data;
      // if (typeof total === 'undefined') {
      //   total = results.length;
      // }

      // if (payload.limit && results.length > payload.limit)  {
      //   const offset = payload.offset || 0;
      //   results = results.splice(offset, payload.limit);
      // }

      await Part.create({
        data: payload.filterFn ? results.filter(payload.filterFn) : results
      });

      Part.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      console.error(err);

      const e = (normalizeError(err) || err);
      Part.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    Part.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await partRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await Part.insert({
        data
      });
      // console.log(payload.id, 'part add', data)
      Part.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Part.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

  }

  @Action
  async findByMR(payload: { recordId: number }) {
    Part.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await partRepository.findByMR(payload.recordId);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: create toggle? instead of just inserting
      if (data.results) {
        Part.insert({
          data: data.results
        });
      }

      Part.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Part.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async createPart(payload: { part: Part }) {
    const { part } = payload;
    const dto: CreatePartDto = {
      name: part.name,
      part_number: part.part_number || '',
      manufacturer: part.manufacturer,
      description: part.description,
      manual_price: part.manual_price,
      public: !!part.public,
      category_id: part.category_id,
    };

    Part.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      const response = await partRepository.create(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      await Part.insert({ data });

      console.log('create  complete');
      Part.commit(state => {
        state.saving = false;
        state.error.length = 0;
        state.lastInsertedId = data.id;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Part.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async updatePart(payload: { part: PartInterface }) {
    const { part } = payload;

    Part.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      if (!part.id) {
        throw 'Missing part id';
        return;
      }

      const dto: UpdatePartDto = {
        id: part.id,
        name: part.name,
        part_number: part.part_number || '',
        manufacturer: part.manufacturer,
        description: part.description,
        manual_price: part.manual_price,
        public: !!part.public,
        category_id: part.category_id,
        url: part.url
      };

      const response = await partRepository.update(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      // TODO update response does not return a valid payload. Treating as no content for now. 
      // if it returned the updated part, we can call Part.insertOrUpdate({data: [data]});
      // await Part.insertOrUpdate({data: [data]});

      console.log('put  complete', data);

      Part.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Part.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async suspendPart(id: number) {
    Part.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });

    if (!id) {
      throw 'Missing part id';
      return;
    }

    const response = await partRepository.remove(id);

    if (response.status < 200 && response.status >= 300) {
      console.error('Invalid 2XX response status');
      throw `Invalid response code: ${response.status}`;
    }

    await Part.delete(id);

    Part.commit(state => {
      state.saving = false;
      state.error.length = 0;
    });
  }
}

export default PartModule;
// export default getModule(PartModule);