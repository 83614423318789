import Vue from 'vue'
import App from './App.vue'
import CoreuiVue from '@coreui/vue'
import { iconsSet as faIcons } from './assets/icons/fa-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { BootstrapVue, ToastPlugin } from 'bootstrap-vue'
import Vuex from 'vuex'
import store from '@/store'
import router from './router'
import mapboxgl from "mapbox-gl";
import VueHtmlToPaper from 'vue-html-to-paper';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';

const env = process.env;
mapboxgl.accessToken = `${env.VUE_APP_MAP_BOX}`;

Vue.config.performance = true

Vue.use(CoreuiVue)
Vue.use(BootstrapVue);
Vue.use(ToastPlugin);
Vue.use(Vuex);
Vue.use(Vuelidate);

Vue.use(VueMask);

library.add(faIcons);

Vue.component('font-awesome-icon', FontAwesomeIcon)

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css'
  ]
}

Vue.use(VueHtmlToPaper, options);

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App
  },
})
