import { AllPayload, Payment, PaymentInterface } from '@/store/modules';
import { PaymentState } from '@/store/types';
import { normalizeError, ErrorInterface } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { paymentRepository, PaymentFlag } from './repository';
import { AxiosResponse } from 'axios';

type ToggleFlagPayload = { id: number; flag: PaymentFlag };

@Module({ namespaced: true })
export class PaymentModule extends VuexModule implements PaymentState {
  // fetching: boolean = false;

  // get lastError() {
  //   console.log('lastError getter', Payment.state())
  //   return  Payment.state().error.map((e: ErrorInterface) => e.getMessage());
  // }

  // get isFetching() {
  //   return  Payment.state().fetching;
  // }

  // get isSaving() {
  //   return  Payment.state().saving;
  // }

  @Action
  async all(payload: AllPayload) {
    Payment.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await paymentRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      await Payment.create({
        data: results.map(payment => {
          if ('ammount' in payment) {
            payment['amount'] = payment['ammount'];
            delete payment['ammount'];
          }
          return payment;
        })
      });

      Payment.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Payment.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    // this.context.commit('fetching', true);
    Payment.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await paymentRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await Payment.insert({
        data
      });

      Payment.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Payment.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async findByMR(payload: { recordId: number }) {
    Payment.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { recordId, ...params } = payload;
      const response = await paymentRepository.findByMR(recordId, params);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // todo: create toggle? instead of just inserting
      if (data.results) {
        await Payment.insert({
          data: data.results.map(payment => {
            if ('ammount' in payment) {
              payment['amount'] = payment['ammount'];
              delete payment['ammount'];
            }
            return payment;
          })
        });
      }

      Payment.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Payment.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action({ rawError: true })
  async toggleFlag(payload: ToggleFlagPayload) {
    try {
      Payment.commit(state => {
        state.saving = true;
        state.error.length = 0;
      });
      const { flag } = payload;
      let c: null | ((id: number) => Promise<AxiosResponse<PaymentInterface>>) = null;

      switch (flag) {
        case 'approve':
          c = (id: number) => paymentRepository.approve(id);
          break;
        case 'void':
          c = (id: number) => paymentRepository.void(id);
          break;
      }

      if (!c) {
        throw `Unknown or undefined flag ${flag}`;
      }

      const { status, data } = await c(payload.id);
      console.log('flag response', data)

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      await Payment.insertOrUpdate({
        data: [data]
      });

      Payment.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);
      Payment.commit(state => {
        state.saving = false;
        state.error = [e];
        console.log('error push', state);
      });

      // throw e;
    }
  }

  @Action
  async approve({ id }: { id: number }) {
    this.context.dispatch('toggleFlag', { id, flag: 'approve' });
  }

  @Action
  async void({ id }: { id: number }) {
    this.context.dispatch('toggleFlag', { id, flag: 'void' });
  }
}

export default PaymentModule;
// export default getModule(PaymentModule);