import { RepositoryInterface } from "@/store/types";
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { MRInterface } from './MR';
import { MRAllPayload, MRApiCollection } from '@/store/modules';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';

export type MRFlag = 'approve' | 'complete' | 'void' | 'paid';

export default class MRRepository implements RepositoryInterface {
    request: AxiosInstance;

    constructor(request: AxiosInstance) {
        this.request = request;
    }

    async fetchData(params?: MRAllPayload, config: AxiosRequestConfig = {}) {
        // console.log('MRRepository::fetchData', params, config);
        return this.request.get('list', params ? { ...config, params } : config);
    }

    async findOne(id: number, config: AxiosRequestConfig = {}) {
        return this.request.get<MRInterface>(`byid/${id}`, config);
    }

    async findByTech(techId: number, params?: MRAllPayload, config: AxiosRequestConfig = {}) {
        return this.request.get<MRApiCollection>(`bytech/${techId}`,  params ? { ...config, params } : config);
    }

    async approve(id: number, config: AxiosRequestConfig = {}) {
        return this.toogleFlag(`approve`, id, config);
    }

    async complete(id: number, config: AxiosRequestConfig = {}) {
        return this.toogleFlag(`complete`, id, config);
    }

    async void(id: number, config: AxiosRequestConfig = {}) {
        return this.toogleFlag(`void`, id, config);
    }

    async paid(id: number, config: AxiosRequestConfig = {}) {
        return this.toogleFlag(`paid`, id, config);
    }

    async toogleFlag(path: MRFlag, id: number, config: AxiosRequestConfig = {}) {
        // const data = new FormData();
        // data.append('id', ''+id);
        const data = { id: '' + id };
        // const headers =  {
        //     'Content-Type': 'multipart/form-data'
        // }
        // console.log({ headers, ...config, data});

        return this.request.patch<MRInterface>(path, data, { ...config });
    }
}

const mrRepository = new MRRepository(axiosProvider('mr', {}, {
    response: [refreshTokenInterceptorProvider()]
}));
export { mrRepository };