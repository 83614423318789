import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { axiosProvider } from '@/store/provider';
import TaskSchedule, { TaskScheduleInterface } from './TaskSchedule';
import { AllPayload, TaskScheduleApiCollection } from '@/store/modules';
import { Collection } from '@vuex-orm/core';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class TaskScheduleRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    // console.log('TaskScheduleRepository::findOne', id, config);
    return this.request.get<TaskScheduleInterface>(`byid/${id}`, config);
    // throw 'Not implemeted yet';
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // throw 'Not implemeted yet';
    // console.log(params, 'all');
    return this.request.get<TaskScheduleApiCollection>('list', params ? { ...config, params } : config);
  }

  async findByTech(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<TaskScheduleApiCollection>> {
    // console.log(params);
    if(!config.transformResponse) {
      config.transformResponse = (data) => {

        const results = JSON.parse(data);

        return {results, total: 0};
      }
    }

    return this.request.get<TaskScheduleApiCollection>(`techSchedule/${recordId}`, params ? { ...config, params } : config);
  }

  async findByCustomer(recordId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse<TaskScheduleApiCollection>> {
    // console.log(params);
    if(!config.transformResponse) {
      config.transformResponse = (data) => {

        const results = JSON.parse(data);

        return {results, total: 0};
      }
    }

    return this.request.get<TaskScheduleApiCollection>(`customerSchedule/${recordId}`, params ? { ...config, params } : config);
  }

  
}

const taskScheduleRepository = new TaskScheduleRepository(axiosProvider('job', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { taskScheduleRepository };