import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { TechnicianState } from '@/store/types';
import { Technician, technicianRepository, AllPayload, mrRepository, MRAllPayload } from '@/store/modules';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { MR } from '../MaintenanceRecord';
import { RegisterAwsDto } from '../../../auth/dto/RegisterAws.dto';
import { authService } from '../../../auth/AuthService';
import { staffRepository } from '../Auth/repository';

@Module
export class TechnicianModule extends VuexModule implements TechnicianState {
  // fetching: boolean = false;

  @Action
  async all(payload: AllPayload) {
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await technicianRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      Technician.create({
        data: results.map(task => {
          /*if ('Job' in task) {
            task['job'] = task['Job'];
            delete task['Job'];
          }*/
          return task;
        })
      });

      Technician.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      console.error(err);
      const e = (normalizeError(err) || err);

      Technician.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    // this.context.commit('fetching', true);
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await technicianRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await Technician.insert({ data });

      Technician.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Technician.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async findMR(payload: { id: number } & MRAllPayload) {
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { id, ...filters } = payload;
      const response = await mrRepository.findByTech(id, filters);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      // plug the mr data
      if (data.results) {
        // MR.insert({ data: data.results });
        // updated to create since filters need to apply here
        MR.create({ data: data.results });
        MR.commit(state => {
          const t = +data.total;
          state.total = isNaN(t) ? 0 : t;
        });
      }

      Technician.commit(state => {
        state.fetching = false;
      });
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Technician.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async registerAndUpdateCognitoId({ techId, ...registerDto }: RegisterAwsDto & { techId: number }) {
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try { 
      const registerResponse = await authService.register(registerDto);

      if(registerResponse && registerResponse.data && registerResponse.data.status === 'Okay') {
        const token = registerResponse.data.result;
        const response = await technicianRepository.updateCognitoId(techId, registerResponse.data.result);

        if (response.status < 200 && response.status >= 300) {
          console.error('Invalid 2XX response status');
          throw `Invalid response code: ${response.status}`;
        }
  
        // const data = response.data;
  
        await Technician.update({
          where: techId,
          data: {
            cognitoID: token
          }
        });

        Technician.commit(state => {
          state.fetching = false;
        });
      }
    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Technician.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async updateCognitoId(payload: { id: number; token: string }) {
    // this.context.commit('fetching', true);
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await technicianRepository.updateCognitoId(payload.id, payload.token);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      // const data = response.data;

      await Technician.update({
        where: payload.id,
        data: {
          cognitoID: payload.token
        }
      });

      Technician.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Technician.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async forcePasswordChange(payload: { email: string, password: string, confirmPassword: string }) {
    // this.context.commit('fetching', true);
    Technician.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await staffRepository.forcePasswordChange(payload);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      Technician.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Technician.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

}

export default TechnicianModule;
// export default getModule(TechnicianModule);