import { Model } from '@vuex-orm/core';

export interface LabourRateInterface {
  id: number;
  effective_at?: string;
  rate?: number;
  comment?: string;
  techID?: number;
  tech_name?: string;
  created_at?: string;
  voided_comment?: string | null;
  voided_at?: string | null;
}

export class LabourRate extends Model implements LabourRateInterface {
  id!: number;
  effective_at?: string;
  rate?: number;
  comment?: string;
  techID?: number;
  tech_name?: string;

  created_at?: string;
  voided_comment?: string | null;
  voided_at?: string | null;


  static entity = 'labourRate';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      id: this.attr(null),

      effective_date: this.string(null).nullable(),
      rate: this.number(null).nullable(),
      comment: this.string(null).nullable(),
      techID: this.string(null).nullable(),
      tech_name: this.string(null).nullable(),

      created_at: this.string(null).nullable(),
      voided_comment: this.string(null).nullable(),
      voided_at: this.string(null).nullable(),
    }
  }

  static isSaving() {
    return this.store().state.entities[this.entity].saving;
  }

  static getErrors() {
    return this.store().state.entities[this.entity].error;
  }
}

export default LabourRate;