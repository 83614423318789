import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { TaskState } from '@/store/types';
import { Task, taskRepository, AllPayload, Job, technicianRepository } from '@/store/modules';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { TaskInterface } from './Task';

type FindOnePayload = { id: number } & { withTechInSchedule?: boolean };

// helper funtion to fix messy payload
const fix = (task: any) => {
  if ('Job' in task) {
    const job = task['Job'] as Job;
    // hack to normalize customer payload since it is linked to jobs
    if ('Customer' in task) {
      job.customer = task['Customer'];
      delete task['Customer'];
    }

    if ('Address' in task) {
      job.address = task['Address'];
      delete task['Address'];
    }

    task['job'] = job as never;
    delete task['Job'];
  }

  if ('Schedule' in task) {
    task['schedule'] = task['Schedule'];
    delete task['Schedule'];
  }

  return task;
};

/**
 * Hack to fetch techs from a schedule payload which is embedded in the task.schedule (after fix)
 */
const injectTechInSchedule = async (task: TaskInterface) => {
  const { schedule } = task;
  if (!schedule || !schedule.length) {
    return;
  }

  const techIds = [...new Set(schedule.map(s => +(s.techID || 0)))]

  for (let i = 0; i < techIds.length; i++) {
    const techId = techIds[i];
    if (!techId) {
      continue;
    }

    try {
      const result = await technicianRepository.findOne(techId);
      if (!result || !result.data) {
        continue;
      }

      for (let j = 0; j < schedule.length; j++) {
        const s = schedule[j];
        if (!s || s.technician || !s.techID) {
          continue;
        }
        const c = +(s.techID);
        if (!c || isNaN(c)) {
          continue;
        }
        if ((+result.data.ID) === c) {
          s.technician = result.data;
        }
      }
    } catch (err) {
      // todo: is it ok to fail silently?
      console.error(err);
    }
  }
}

@Module
export class TaskModule extends VuexModule implements TaskState {
  // fetching: boolean = false;

  @Action
  async all(payload: AllPayload) {
    Task.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await taskRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;
      const fixResults = results.map(result => {
        const task = result.info;

        return fix(task);
      });
      await Task.create({
        data: fixResults
      });

      Task.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      Task.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: FindOnePayload) {
    // this.context.commit('fetching', true);
    Task.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await taskRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = fix(response.data);
      if (payload.withTechInSchedule === true) {
        // const unique = [...new Set(data.map(item => item.group))];
        await injectTechInSchedule(data);
      }

      await Task.insert({ data });

      Task.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Task.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  @Action
  async upcoming() {
    Task.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const tasks = await taskRepository.today();
      // if (response.status < 200 && response.status >= 300) {
      //   console.error('Invalid 2XX response status');
      //   throw `Invalid response code: ${response.status}`;
      // }

      const data = tasks.map(task => fix(task));

      await Task.create({
        data
      });

      Task.commit(state => {
        state.fetching = false;
        state.total = data.length;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Task.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }

  @Action
  async queued() {
    Task.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const tasks = await taskRepository.queued();
      // if (response.status < 200 && response.status >= 300) {
      //   console.error('Invalid 2XX response status');
      //   throw `Invalid response code: ${response.status}`;
      // }

      const data = tasks.map(task => fix(task));

      await Task.create({
        data
      });

      Task.commit(state => {
        state.fetching = false;
        state.total = data.length;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Task.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }
  }
}

export default TaskModule;
// export default getModule(TaskModule);