import { AnyObject } from './types';

export interface ErrorInterface {
  message: string[];

  getMessage(): string | null;
}

class Error implements ErrorInterface {
  message: string[];

  constructor(msg: string | string[]) {
    if (typeof msg === 'string') {
      this.message = [msg];
    } else {
      this.message = msg;
    }
  }

  getMessage() {
    if (this.message.length) {
      return this.message[0];
    }
    return null;
  }
}


export function normalizeError(err: string | AnyObject | string[]): Error | null {
  if (typeof err === 'string' || Array.isArray(err)) {
    return new Error(err);
  }

  if ((err instanceof Error)) {
    return err;
  }

  if (typeof err === 'object') {
    if (err.response) {
      if (err.response.data && err.response.data.message) {
        return new Error(err.response.data.message)
      }
      // return new Error(err.response.bo)
    }
    const k = 'message';

    if ('message' in err) {
      // return normalizeError(err.message);
      return new Error(err['message']);
    }
  }

  return null;
}