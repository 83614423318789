import axiosProvider from './AxiosProvider';
import { AxiosRequestConfig } from 'axios';
import { queryInterceptor } from './intercept';

const host = process.env.VUE_APP_BASE_HOST;
const protocol = 'https';
const interceptors = {
    request: [queryInterceptor]
};

// todo
const testProvider = (
    serviceKey: string,
    axiosConfig: AxiosRequestConfig = {}
) => axiosProvider(`${protocol}://${serviceKey}.${host}`, axiosConfig, interceptors);

export { testProvider };