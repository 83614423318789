import { Model } from '@vuex-orm/core';
import { Technician } from '../Technician';

export interface GeoLocationInterface {
  time: string;
  timestamp: number;
  tech_id?: number;
  tech_name?: string;
  mr_id?: number;
  lat: number;
  long: number;
}

export class GeoLocation extends Model implements GeoLocationInterface {

  time!: string;
  timestamp!: number;
  tech_id?: number;
  tech_name?: string;
  mr_id?: number;
  lat!: number;
  long!: number;

  static entity = 'locations'
  static primaryKey = ['time', 'tech_id']

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      time: this.string(null).nullable(),
      timestamp: this.number(0),
      tech_id: this.number(null).nullable(),
      tech_name: this.string(null).nullable(),
      mr_id: this.number(null).nullable(),
      lat: this.number(null).nullable(),
      long: this.number(null).nullable(),
      tech: this.belongsTo(Technician, 'tech_id', 'ID')
    }
  }
}