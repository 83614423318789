import {
  faAddressBook, faBullhorn, faCalendarDay, faChartLine,
  faCubes, faFileAlt, faFileInvoice,
  faHeadphones,
  faHome, faLayerGroup, faList, faPlus,
  faQuestion,
  faRuler, faTrafficLight,
  faTruck,
  faUsers, faVideo, faUser,
  faCalendar, faWrench, faMap, faMoneyCheck, faAddressCard, faDigging,
  faCheck, faTimes, faForward, faBackward, faPlay, faPause,
  faPencil, faTrash,
  faSpinner,
  faPrint,
  faFileInvoiceDollar, faCheckCircle, faCogs, faFileCertificate, faSignature, faHandshakeAltSlash, faExternalLink,
  faEye, faEyeSlash, faEllipsisV, faMapMarkerAlt, faLocation,
  faShoppingCart, faHandshake, faHourglass, faHourglassStart,
  faThermometer, faThermometerEmpty, faThermometerFull, faThermometerHalf, faThermometerThreeQuarters, faThermometerQuarter,
  faBolt,
  faHumidity,
  faUserSecret,
  faCalculator,
  faStopwatch
} from "@fortawesome/pro-light-svg-icons";

export const iconsSet = {
  faStopwatch,
  faCalculator,
  faHome,
  faTruck,
  faCubes,
  faRuler,
  faQuestion,
  faUsers,
  faUser,
  faHeadphones,
  faAddressBook,
  faList,
  faPlus,
  faCalendarDay,
  faBullhorn,
  faFileAlt,
  faFileInvoice,
  faTrafficLight,
  faLayerGroup,
  faVideo,
  faChartLine,
  faCalendar, faWrench, faMap, faMoneyCheck, faAddressCard,
  faDigging,
  faCheck, faTimes,
  faForward, faBackward, faPlay, faPause,
  faPencil, faTrash,
  faSpinner,
  faPrint,
  faExternalLink,
  // MR icons
  faFileInvoiceDollar, faCheckCircle, faCogs, faFileCertificate, faSignature, faHandshakeAltSlash, faHandshake,
  faEye, faEyeSlash,
  faEllipsisV, faMapMarkerAlt, faLocation,
  faShoppingCart, faHourglass, faHourglassStart,
  faThermometer, faThermometerEmpty, faThermometerFull, faThermometerHalf, faThermometerThreeQuarters, faThermometerQuarter,
  faBolt,
  faHumidity,
  faUserSecret,
};