import { RouteConfig } from 'vue-router';

// Maintenance Record (MR) components
const TimesheetIndex = () => import(/* webpackChunkName: "timesheet-group" */ '@/pages/timesheet/TimesheetIndex.vue')
const TimesheetListPage = () => import(/* webpackChunkName: "timesheet-group" */ '@/pages/timesheet/TimesheetListPage.vue')
// const TimesheetIndexDetail = () => import(/* webpackChunkName: "timesheet-group" */ '@/pages/timesheet/TimesheetIndexDetail.vue')

const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const routes: RouteConfig = {
  path: 'timesheet',
  name: 'Timesheet',
  component: SlidePage,
  redirect: '/timesheet/',
  children: [
    {
      path: '/',
      component: TimesheetListPage,
      name: 'Timesheet Report',
      // children: [{
      //   path: '/',
      //   component: TimesheetListHandler,
      //   name: "Timesheet Report"
      // }]
    },
    /*{
      path: ':id',
      component: TaskDetail,
      props: true,
      name: "Timesheet Detail"
    },*/
    {
      path: '*',
      name: 'Underconstruction',
      component: UnderConstruction
    }
  ]
};

export default routes;