import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { TimesheetInterface } from './Timesheet';
import { AllPayload, TimesheetApiCollection, TimesheetAllPayload } from '@/store/modules';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';



export class TimesheetRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<TimesheetInterface>(`show/${id}`, config);
  }

  async all(params: TimesheetAllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<TimesheetApiCollection>('/time/list', params ? { ...config, params } : config);
  }

  async findByTech(techId: number, params?: TimesheetAllPayload, config: AxiosRequestConfig = {}) {
    return this.request.get<TimesheetInterface[]>(`timebytech/${techId}`, params ? { ...config, params } : config);
  }
}

const timesheetRepository = new TimesheetRepository(axiosProvider('mr', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { timesheetRepository };