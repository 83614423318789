import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { Technician } from '../Technician';
import { Task } from '@/store/modules';

export interface PaymentInterface {
  id: number;
  type?: string;
  referenceNumber?: string;
  jobNumber?: string;
  taskNumber?: string;
  address_data?: string;
  customer_name?: string;
  tech_name?: string;
  created_at?: string;
  updated_at?: string;
  amount?: number;
  approved?: boolean;
  voided?: boolean;
  technician?: Technician;
  techID?: number | null;
  mrID?: number | null;
  taskID?: number | null;
}

export class Payment extends Model implements PaymentInterface {
  id!: number;
  type?: string;
  referenceNumber?: string;
  jobNumber?: string;
  taskNumber?: string;
  address_data?: string;
  customer_name?: string;
  tech_name?: string;
  created_at?: string;
  updated_at?: string;
  amount?: number;
  approved?: boolean;
  voided?: boolean;
  technician?: Technician;
  techID?: number | null;
  mrID?: number | null;
  taskID?: number | null;

  static entity = 'payment';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      saving: false,
      error: []
    }
  }

  static isSaving() {
    return this.store().state.entities[this.entity].saving;
  }

  static getErrors() {
    return this.store().state.entities[this.entity].error;
  }

  static fields() {
    return {
      id: this.attr(null),
      type: this.string(null).nullable(),
      referenceNumber: this.string(null).nullable(),
      jobNumber: this.string(null).nullable(),
      taskNumber: this.string(null).nullable(),
      address_data: this.string(null).nullable(),
      customer_name: this.string(null).nullable(),
      tech_name: this.string(null).nullable(),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable(),

      amount: this.number(null).nullable(),
      mrID: this.number(null).nullable(),
      taskID: this.number(null).nullable(),
      customerID: this.number(null).nullable(),
      addressID: this.number(null).nullable(),
      techID: this.number(null).nullable(),
      jobID: this.number(null).nullable(),


      approved: this.boolean(null).nullable(),
      voided: this.boolean(null).nullable(),

      mr: this.belongsTo(MR, 'mrID', 'id'),
      technician: this.belongsTo(Technician, 'techID', 'ID'),

      task: this.belongsTo(Task, 'taskID', 'ID')
    }
  }
}

export default Payment;