import { RouteConfig } from 'vue-router'

// Maintenance Record (LabourRate) components
const LabourRateIndex = () => import(/* webpackChunkName: "labour-rate-group" */ '@/pages/labour-rate/LabourRateIndex.vue')
const LabourRateDetail = () => import(/* webpackChunkName: "labour-rate-group" */ '@/pages/labour-rate/LabourRateDetail.vue')
// const LabourRatePrint = () => import(/* webpackChunkName: "labour-rate-group" */ '@/pages/labour-rate/LabourRatePrint.vue')
const LabourRateListHandler = () => import(/* webpackChunkName: "labour-rate-group" */ '@/pages/labour-rate/LabourRateListHandler.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');
const LabourRateAdd = () => import(/* webpackChunkName: "labour-rate-group" */ '@/pages/labour-rate/LabourRateAdd.vue')

const route: RouteConfig = {
  path: 'labour-rate',
  // name: 'Labour Rate',
  component: SlidePage,
  redirect: '/labour-rate/',
  children: [
    {
      path: '/',
      component: LabourRateIndex,
      name: "Labour Rate",
      children: [
        {
          path: '/',
          component: LabourRateListHandler,
          name: "Labour Rate"
        },
        {
          path: 'add',
          component: LabourRateAdd,
          props: true,
          name: "Labour Rate Add"
        },
        {
          path: ':id',
          component: LabourRateDetail,
          props: true,
          name: "Record Detail"
        }
      ]
    },
    {
      path: '*',
      name: 'LabourRate',
      component: UnderConstruction
    }
  ]
}

export default route;