import { Customer, CustomerAddressInterface, CustomerAllPayload, customerRepository, FindOneActionPayload, WithAddressActionPayload } from '@/store/modules';
import { CustomerState } from '@/store/types';
import { makeCancelable, normalizeError } from '@/utility';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Action, Module, VuexModule } from 'vuex-module-decorators';
import { Address } from '../Address';


type FindOnePayload = FindOneActionPayload & WithAddressActionPayload;

async function pullCustomerAddress(payload: { addresses: CustomerAddressInterface[] }) {
  return makeCancelable(new Promise((ok, fail) => {
    const src$ = of<CustomerAddressInterface[]>(payload.addresses).pipe(
      mergeMap((mrs) => {
        return forkJoin(...mrs.map(record => {
          if (!record.addressID) {
            return of(null);
          }

          return Address.dispatch('findOne', { id: +record.addressID })
        }));
      })
    );

    src$.subscribe(() => ok(null));
  }));
}

@Module
export class CustomerModule extends VuexModule implements CustomerState {

  // fetching: boolean = false;
  @Action({ rawError: true })
  async all(payload: CustomerAllPayload) {
    Customer.commit(state => {
      state.fetching = true;
      state.error.length = 0;
      state.data = [];
    });


    try {
      const { status, data } = await customerRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { total, results } = data;

      await Customer.create({ //fix broken array
        data: payload.filterFn ? Object.values(results).filter(payload.filterFn) : Object.values(results)
      });

      Customer.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      console.error(err);

      const e = (normalizeError(err) || err);
      Customer.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: FindOnePayload) {
    // this.context.commit('fetching', true);

    Customer.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await customerRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (payload.withAddress && data.addresses && data.addresses.length) {
        await pullCustomerAddress({ addresses: data.addresses });
      }

      // console.warn(data);
      Customer.insert({ data });

      Customer.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Customer.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }


}

export default CustomerModule;
// export default getModule(CustomerModule);