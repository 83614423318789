import { ReponseInterceptors } from '../types';
import Axios, { AxiosRequestConfig, AxiosError, AxiosPromise, AxiosResponse } from 'axios';
// import AuthModule from '@/store/modules/Auth/AuthModule';
import { refreshOptionsProvider } from '../../../auth/RefreshOptionProvider';
import { RefreshTokenInterceptorOptions } from '@/store/provider';

const FORBIDDEN = 403;
const UNAUTHORIZED = 401;

// https://stackoverflow.com/questions/52246393/rxjs-subscription-queue/52250016
let isRefreshing = false;

type RequestTask = AxiosRequestConfig & { _retryRefresh?: number };
interface BufferTask {
  resolve: (value?: string | PromiseLike<string> | undefined) => void;
  reject: <T = any | AxiosError>(value?: T) => void;
  ori: RequestTask;
}

class BufferQueue {

  // private queue$ = new Subject<RequestTask[]>();
  // private process$ = new Subject<RequestTask[]>();

  private buffer: BufferTask[] = [];

  private timeout?: NodeJS.Timeout;

  delay = 200;

  constructor() {
    // const proc$ = this.queue$.pipe(
    //   tap(t => console.log('Enqueue Task', t)),
    //   mergeMap(t => t),
    //   // possibly filter?
    //   // contact if we care about the order
    // );

    // proc$.subscribe((task) => {
    //   console.log('Processing', task)
    // })
  }

  async enqueue(task: RequestTask): Promise<string | AxiosResponse | AxiosError> {
    const p = new Promise<string>((resolve, reject) => {
      console.info('Queue up request buffer for refresh', task);
      this.buffer.push({ resolve, reject, ori: task })
    });

    return p
      // if flush success
      .then(this.injectAuthToken(task))
      // if flush error
      .catch(err => Promise.reject(err));
  }

  async flushSuccess(token: string) {
    return this.process<string>((buffer) => {
      for (let i = 0; i < buffer.length; i++) {
        const b = buffer[i];
        b.resolve(token);
      }
      console.info('Refresh queue has been resolved');
      return token;
    });
  }

  async flushError<T = any | AxiosError>(err: T) {
    return this.process((buffer) => {
      for (let i = 0; i < buffer.length; i++) {
        const b = buffer[i];
        b.reject(err);
      }
      console.error('Refresh queue has been rejected with', err);
      return err;
    });
  }

  private async process<T>(proc: (buffer: BufferTask[]) => T) {
    // make the processing of the buffer async so that we may choose to
    // delegate it to a worker in the future
    return new Promise<T>((resolve, reject) => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        // todo. Will flushError make it so the buffer doesn't get handled properly? needs testing 
        try {
          if (this.isEmpty()) {
            console.info('Processing buffer is empty.');
            return proc([]);
          }
          // pull out of array to make sure it doesn't get process twice
          // proc function can enqueue if needed
          const tasks = this.buffer.splice(0);
          console.info(`Processing ${tasks.length} tasks from buffer`);

          return resolve(proc(tasks))
        } catch (err) {
          reject(err)
        }

      }, this.delay)
    })
  }

  private injectAuthToken(r: RequestTask) {
    return (token: string) => {
      r.headers.Authorization = `Bearer ${token}`;
      // replay the axios request
      return Axios(r);
    };
  }

  isEmpty() {
    return this.buffer.length === 0;
  }
}

const queue = new BufferQueue();
const MAX_RETRY = 1;

// the default refresh using the auth module
// const defaultRefresh = (token: string) => {
//   // utilize the module for refreshing the token
//   // return AuthModule.authRefresh(token)
//   return Promise.resolve();
// };

// const defaultRefreshTokenGetter = () => {
//   return Promise.resolve('test');
// }

// const defaultJWTTokenGetter = () => {
//   return Promise.resolve('test');
// }

// const defaultOptions = {
//   handleLogout: async () => {
//     console.log('todo: handle logouts')
//     // store.dispatch(authActions.authLogoutAsync.request());
//   },
//   handleRefresh: defaultRefresh,
//   getRefreshToken: defaultRefreshTokenGetter,
//   getAccessToken: defaultJWTTokenGetter
// };

const defaultOptions = {};

type RefreshOptions = (Partial<RefreshTokenInterceptorOptions>);
type R = () => (Promise<RefreshTokenInterceptorOptions>)

// see https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
export const refreshTokenInterceptorProvider = (
  refreshOptions?: RefreshOptions | R): ReponseInterceptors<any> => ({
    reject: async (err) => {
      if (typeof err !== 'object') {
        return Promise.reject(err);
      }
      const { response, config } = err;
      let loadOpt = {};

      if (!refreshOptions) {
        refreshOptions = refreshOptionsProvider;
      }

      if (typeof refreshOptions === 'function') {
        loadOpt = await refreshOptions();
      } else {
        loadOpt = refreshOptions || {};
      }
      // const defaultOptions = await refreshOptionsProvider();
      const options = { ...defaultOptions, ...loadOpt } as RefreshTokenInterceptorOptions;
      // if the response return a 401 or 403 then we attempt to refresh the token
      if (!response || !response.status || (response.status !== FORBIDDEN && response.status !== UNAUTHORIZED)) {
        return Promise.reject(err);
      }

      // 0.19.1 allows for this again
      // basically keep track of the number of times a retry attempt was made
      const ori = config as (AxiosRequestConfig & { _retryRefresh?: number });

      // have it default to 0 if not set otherwise check if too many retried attempts we made
      if (typeof ori._retryRefresh === 'undefined') {
        ori._retryRefresh = 0;
      } else if (ori._retryRefresh >= MAX_RETRY) {
        console.error(`Max retry attempts reached ${ori._retryRefresh}`);
        return Promise.reject(err);
      }

      // if there are several requests being made (ie looping and running several ajax calls)
      // the we queue it up to the buffer
      if (isRefreshing) {
        // console.error('isRefreshing. Push to queue');
        return queue.enqueue(ori);
      }

      isRefreshing = true;
      ori._retryRefresh++;

      const { handleLogout, handleRefresh, getRefreshToken, getAccessToken } = options;

      // check for an existing request token and queue up the current request
      // note that enqueue returns a promise that will inject auth token and replay the request
      const refreshToken = await getRefreshToken();
      const current = queue.enqueue(ori);

      // todo: Not sure if this is the correct way to handle errors for this situation
      // should the reject map to the current?
      if (!refreshToken) {
        return queue.flushError(err).catch((err) => {
          console.error('Flush Error on missing refresh token', err);
          throw err;
        });
      }

      try {
        const r = await handleRefresh(refreshToken);

        const accessToken = await getAccessToken();

        // the auth module needs better mechanism for checking refresh status
        if (!accessToken) {
          throw err;
        }
        queue.flushSuccess(accessToken);

        return current;
      } catch (e) {
        return queue
          .flushError(err)
          .then(() => {
            console.error('Logging out due to failed refresh request');
            return handleLogout();
          })
          .catch((err) => {
            console.error('Refresh response Error', err);
            throw err;
          });
      }
      // begin the refresh token logic
      // return new Promise((resolve, reject) => {

      // });
    }
  });

// export const refreshTokenInterceptorx: ReponseInterceptors<AxiosRequestConfig> = {
//   reject: async (err) => {
//     if (typeof err !== 'object') {
//       return Promise.reject(err);
//     }
//     const { response, config } = err;

//     // if the response return a 401 or 403 then we attempt to refresh the token
//     if (!response || !response.status || (response.status !== FORBIDDEN && response.status !== UNAUTHORIZED)) {
//       return Promise.reject(err);
//     }

//     // 0.19.1 allows for this again
//     // basically keep track of the number of times a retry attempt was made
//     const ori = config as (AxiosRequestConfig & { _retryRefresh?: number })

//     // have it default to 0 if not set otherwise check if too many retried attempts we made
//     if (typeof ori._retryRefresh === 'undefined') {
//       ori._retryRefresh = 0;
//     } else if (ori._retryRefresh >= MAX_RETRY) {
//       console.error(`Max retry attempts reached ${ori._retryRefresh}`);
//       return Promise.reject(err);
//     }

//     // if there are several requests being made (ie looping and running several ajax calls)
//     // the we queue it up to the buffer
//     if (!isRefreshing) {
//       console.error('isRefreshing. Push to queue');
//       return queue.enqueue(ori);
//     }

//     isRefreshing = true;
//     ori._retryRefresh++;

//     // begin the refresh token logic
//     return new Promise((resolve, reject) => {
//       // check for an existing request token and queue up the current request
//       // note that enqueue returns a promise that will inject auth token and replay the request
//       const token = AuthModule.refreshToken, current = queue.enqueue(ori);

//       // todo: Not sure if this is the correct way to handle errors for this situation
//       // should the reject map to the current?
//       if (!token) {
//         return queue.flushError(err).catch((err) => reject(err));
//       }

//       // utilize the module for refreshing the token
//       AuthModule.authRefresh(token).then(() => {
//         const jwt = AuthModule.accessToken;

//         // the auth module needs better mechanism for checking refresh status
//         if (!jwt) {
//           throw err;
//         }
//         queue.flushSuccess(jwt);
//         // The current promise should resolve when the queue consumes the task
//         return resolve(current);
//       })
//         .catch((err) => queue.flushError(err).catch((err) => reject(err)))
//         .finally(() => {
//           // make sure this flag is set to false regardless of the state
//           isRefreshing = false;
//         })
//     });

//   }
// }