import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { GeoLocation } from '../GeoLocation';
import { Timesheet } from '../Timesheet';
import { TaskSchedule } from '../TaskSchedule';
import { PartRequest } from '../PartRequest';
import { Technician } from '../Technician';

export interface UserInterface {
  ID: number;
  type: string;
  refID: number;
  profile: Technician;
}

export class User extends Model implements UserInterface {
  ID!: number;
  type!: string;
  refID!: number;
  profile!: Technician;


  static entity = 'user';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      type: this.string(null).nullable(),
      refID: this.number(null).nullable(),
      profile: this.hasOne(Technician, 'ID', 'refID')
    }
  }
}

export default User;