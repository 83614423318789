import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { UserState } from '@/store/types';
import { User, userRepository, AllPayload, mrRepository, MRAllPayload } from '@/store/modules';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';
import { MR } from '../MaintenanceRecord';

@Module
export class UserModule extends VuexModule implements UserState {

  @Action
  async me() {
    // this.context.commit('fetching', true);
    User.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await userRepository.registered();

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;
      console.log('user action response', data)
      await User.create({ data });

      User.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      User.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }


}

export default UserModule;