import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import { axiosProvider } from '@/store/provider';
import { SignatureInterface } from './Signature';
import { AllPayload, SignatureApiCollection } from '@/store/modules';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';


export class SignatureRepository implements RepositoryInterface {
  request: AxiosInstance;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<SignatureInterface>(`api/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    return this.request.get<SignatureApiCollection>('api', params ? { ...config, params } : config);
  }

  async downloadSvg(signatureId: string, config: AxiosRequestConfig = {}) {
    // Buffer.from(response.data, 'binary').toString('base64')
    // https://signature.api.kbmercury.com/api/f856346f-4bdb-4ecd-87d9-30884de93b36/svg
    return this.request.get<string>(`api/${signatureId}/svg`, {responseType: 'blob', ...config});
  }

}

const signatureRepository = new SignatureRepository(axiosProvider('signature', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export { signatureRepository };