export interface TokenStorageInterface {
  getToken(): string;
  setToken(token: string): void;
  getStorage(): Storage;
}

/**
 * @deprecated Use async version in auth
 */
export default class TokenStorage implements TokenStorageInterface {

  name: string;

  storage: Storage;

  constructor(name: string, storage: Storage) {
    this.name = name;
    this.storage = storage;
  }

  getToken(): string {
    return this.storage.getItem(this.name) as string;
  }

  setToken(token: string): void {
    this.storage.setItem(this.name, token);
  }

  getStorage(): Storage {
    return this.storage;
  }
}

const tokenStorage = new TokenStorage('auth__access_token', window.localStorage);

export { tokenStorage };