import { RouteConfig } from 'vue-router';

// Maintenance Record (MR) components
const TaskIndex = () => import(/* webpackChunkName: "task-group" */ '@/pages/task/TaskIndex.vue')
const TaskDetail = () => import(/* webpackChunkName: "task-group" */ '@/pages/task/TaskDetail.vue')

const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const routes: RouteConfig = {
  path: '/task',
  name: 'Task',
  redirect: '/task/',
  component: SlidePage,
  children: [
    {
      path: '/',
      component: TaskIndex
    },
    {
      path: ':id',
      component: TaskDetail,
      props: true,
      name: "Task Detail"
    },
    {
      path: '*',
      name: 'Task catch all',
      component: UnderConstruction
    }
  ]
};

export default routes;