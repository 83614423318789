import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { MRNoteState } from '@/store/types';
import { MRNote, AllPayload, mrNoteRepository } from '@/store/modules';
// import {taskRepository} from './repository';
import { normalizeError } from '@/utility';

// NOTE! Not implemented yet. When rest call exists update this and database definition
@Module
export class MRNoteModule extends VuexModule implements MRNoteState {
  // fetching: boolean = false;

  @Action
  async all(payload: AllPayload) {
    MRNote.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await mrNoteRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results, total } = data;

      MRNote.create({
        data: results
      });

      MRNote.commit(state => {
        state.fetching = false;
        state.total = +total;
      });

    } catch (err) {
      console.error(err);
      const e = (normalizeError(err) || err);

      MRNote.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    
    // this.context.commit('fetching', true);
    MRNote.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await mrNoteRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      MRNote.insert({ data });

      MRNote.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      MRNote.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }

  
}

export default MRNoteModule;
// export default getModule(MRNoteModule);