import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {axiosProvider} from '@/store/provider';
import { JobInterface } from './Job';
import { refreshTokenInterceptorProvider } from '../../provider/intercept';
import { AllPayload, JobApiCollection } from '../helper-types';
// import { Observable } from 'rxjs';

export class JobRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  async findOne(id: number, config: AxiosRequestConfig = {}) {
    return this.request.get<JobInterface>(`show/${id}`, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<JobApiCollection>('list', params ? { ...config, params } : config);
  }

  async queued(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    // console.log(params);
    return this.request.get<any[]>('queued', params ? { ...config, params } : config);
  }
}

const jobRepository = new JobRepository(axiosProvider('job', {}, {
  response: [refreshTokenInterceptorProvider()]
}));

export {jobRepository};