import { AllPayload, GeoLocationApiCollection, GeoLocationInterface, PaginateQueryPayload } from '@/store/modules';
import { axiosProvider } from '@/store/provider';
// import { Observable } from 'rxjs';
import { refreshTokenInterceptorProvider } from '@/store/provider/intercept';
import { RepositoryInterface } from '@/store/types';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import moment from 'moment';

type InfluxValue = string | number | boolean | null | undefined;
type InfluxModel = GeoLocationInterface & { [key: string]: InfluxValue };

interface InfluxSeries {
  name: string;
  columns: Array<keyof InfluxModel | undefined>;
  values: Array<InfluxValue[]>;
  tags?: Partial<InfluxModel>;
}

interface InfluxResultPayload {
  results: Array<{
    statement_id: number;
    series: InfluxSeries[];
  }>;
}

const pullTechFromTag = (payload: InfluxResultPayload) => {
  const results = payload.results[0];
  const { series } = results;
  if (!series) {
    return [];
  }

  const ids = [];
  for (let i = 0; i < series.length; i++) {
    const serie = series[i];
    const { tags } = serie;
    if (!tags) {
      continue;
    }

    if (!tags.tech_id || isNaN(+tags.tech_id)) {
      continue;
    }

    ids.push(+tags.tech_id);
  }

  return ids;
}

const translateInfluxToGeoLocation = (payload: InfluxResultPayload): GeoLocationApiCollection => {
  if (!payload || !payload.results) {
    return { total: 0, results: [] };
  }
  const results = payload.results[0];
  const m = [];
  const { series } = results
  if (!series) {
    return { total: 0, results: [] };
  }

  const t = (columns: Array<keyof InfluxModel | undefined>, tags?: Partial<InfluxModel>) => (val: InfluxValue[]) => {

    const d: InfluxModel = {
      time: '',
      timestamp: 0,
      lat: 0,
      long: 0,
      ...(tags || {})
    };

    for (let i = 0; i < columns.length; i++) {
      const k = columns[i];
      if (typeof k === 'undefined') {
        continue;
      }
      d[k] = val[i];
    }

    if (d.time) {
      // moment().unix() for seconds instead of ms
      d.timestamp = moment(d.time).valueOf();
    }

    return d;
  };


  for (let i = 0; i < series.length; i++) {
    const serie = series[i];
    const { columns, values, tags } = serie;
    const o = values.map(t(columns, tags));
    // o.tech_id = Number(o.tech_id);
    m.push(...o);
  }

  const r: GeoLocationApiCollection = {
    total: m.length,
    results: m
  };

  return r;
  // return { total: 0, results: [] };
};


export { translateInfluxToGeoLocation, pullTechFromTag };

export class GeoLocationRepository implements RepositoryInterface {
  request: AxiosInstance;

  // cache$ :  Observable<Map<number, MRInterface>>;

  constructor(request: AxiosInstance) {
    this.request = request;
  }

  private createQuery(q: string[]) {
    const data = new FormData();
    data.append('q', q.join(' '));
    return data;
  }

  async latestByTech(end?: string, config: AxiosRequestConfig = {}) {
    const t = end || moment().subtract(1, 'minute').utc().format();

    const q = [
      `SELECT * FROM location `,
      `WHERE lat !='0' AND long != 0 AND time >= '${t}'`, // Note that lat is a string for some reason
      `GROUP BY tech_id`,
      `ORDER BY time DESC LIMIT 1`
    ];

    return this.request.post<InfluxResultPayload>(`/query?db=locations`, this.createQuery(q), config);
  }

  async byRange(
    start: string,
    end: string,
    page: PaginateQueryPayload = { limit: 25 },
    config: AxiosRequestConfig = {}
  ) {
    // const data = new FormData();
    const q = [
      `SELECT * FROM location `,
      `WHERE time >= '${start}' AND time <= '${end}' `,
      ` AND lat !='0' AND long != 0`, // Note that lat is a string for some reason
      `GROUP BY tech_id  ORDER BY time DESC`
    ];

    if (page.limit) {
      q.push(`LIMIT ${page.limit}`);
    }

    // data.append('q', q.join(' '));
    return this.request.post<InfluxResultPayload>(`/query?db=locations`, this.createQuery(q), config);
  }

  async since(
    start: string,
    page: PaginateQueryPayload = { limit: 25 },
    config: AxiosRequestConfig = {}
  ) {
    // const t = moment(start).subtract(1, 'minute').utc().format();
    const q = [
      `SELECT * FROM location `,
      `WHERE time <= '${start}'`,
      ` AND lat !='0' AND long != 0`, // Note that lat is a string for some reason
      `GROUP BY tech_id ORDER BY time DESC`
    ];

    if (page.limit) {
      q.push(`LIMIT ${page.limit}`);
    }

    // data.append('q', q.join(' '));
    return this.request.post<InfluxResultPayload>(`/query?db=locations`, this.createQuery(q), config);
  }

  async byTech(techId: number, params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    const data = new FormData();
    const q = [`SELECT * FROM location where tech_id = '${techId}'`];
    if (params.sort) {
      q.push(`ORDER BY ${params.sort} ${params.order || 'DESC'}`);
    }

    if (params.limit) {
      q.push(`LIMIT ${params.limit}`);
    }

    data.append('q', q.join(' '));
    return this.request.post<InfluxResultPayload>(`/query?db=locations`, data, config);
  }

  async all(params: AllPayload = {}, config: AxiosRequestConfig = {}) {
    const data = new FormData();
    data.append('q', 'SELECT * FROM location');
    return this.request.post<InfluxResultPayload>(`/query?db=locations`, data, config);
    // should be get 
    // return this.request.post<InfluxResultPayload>(`/query?db=locations`, data, config).then(response => {
    //   if (!response.data || !response.data.results.length) {
    //     return {
    //       total: 0,
    //       results: []
    //     };
    //   }
    //   const results = response.data.results[0];
    //   const { columns, values } = results.series[0];
    //   const m = (val: InfluxValue[]) => {

    //     const d: GeoLocationInterface = {
    //       time: '',
    //       timestamp: 0,
    //       lat: 0,
    //       long: 0
    //     };

    //     for (let i = 0; i < columns.length; i++) {
    //       const k = columns[i];
    //       // if (null === val[i]) {
    //       //   continue;
    //       // }
    //       // @ts-ignore
    //       d[k] = val[i];
    //     }

    //     if (d.time) {
    //       // moment().unix() for seconds instead of ms
    //       d.timestamp = moment(d.time).valueOf();
    //     }

    //     return d;
    //   };

    //   const r: GeoLocationApiCollection = {
    //     total: values.length,
    //     results: values.map(m)
    //   };

    //   return r;
    // });
  }
}

const axios = axiosProvider('location', {}, {
  response: [refreshTokenInterceptorProvider()]
});

// // no data svc implemented yet
// const mock = new MockAdapter(axios);


// const m = 500;


// function getRandomIntInclusive(min: number, max: number) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive 
// }

// const staff = [{
//   id: 1,
//   name: 'Tangle Media'
// },
// {
//   id: 2,
//   name: 'Chris Bruce'
// }];

// const values: Array<InfluxValue[]> = [];

// const run = (staff: {id: number, name: string}) => {
//   const now = moment();
//   const ts = moment();
//   ts.subtract('3', 'd');
//   const center = [49.6861184, -112.80384];
//   const re = 6378;
//   const latitude = center[0], longitude = center[1];

//   for(let i = 0; i < m; i++) { 
//     const dy = getRandomIntInclusive(1,10);
//     const dx = getRandomIntInclusive(1,10);
//     const new_latitude  = latitude  + (dy / re) * (180 / Math.PI);
//     const new_longitude = longitude + (dx / re) * (180 / Math.PI) / Math.cos(latitude * Math.PI/180);

//     ts.add(getRandomIntInclusive(1, 5), 'm');

//     const v = [
//       ts.toISOString(),
//       staff.id,
//       staff.name,
//       1,
//       new_latitude,
//       new_longitude
//     ];

//     values.push(v);

//     if (ts.isAfter(now)) {
//       console.error('over flow');
//       break;
//     }
//   }
// };

// run(staff[0]);
// run(staff[1]);

// mock.onGet(`/list`).reply(200, {
//   "results": [
//     {
//       "statement_id": 0,
//       "series": [
//         {
//           "name": "tech_location",
//           "columns": [
//             "time",
//             "tech_id",
//             "tech_name",
//             "mr_id",
//             "lat",
//             "long"
//           ],
//           "values": values
//         }
//       ]
//     }
//   ]
// });


const geoLocationRepository = new GeoLocationRepository(axios);

export { geoLocationRepository };

