import { Model } from '@vuex-orm/core';

export interface SignatureInterface {
  id: number;
  date_signed?: string;
}

export class Signature extends Model implements SignatureInterface {
  id!: number;
  date_signed?: string;
  
  static entity = 'signature';
  static primaryKey = 'id'
  
  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      id: this.attr(null),
      date_signed: this.string(null).nullable()
    }
  }
}

export default Signature;