import { TokenStorageInterface } from "./interface";
import { tokenStorage } from "./TokenStorage";
import { AuthResponsePayload } from './types';

export interface AuthTokenPayload extends AuthResponsePayload {
  // user input
  userEmail?: string;
}

export class AuthTokenStorage {
  storage: TokenStorageInterface;

  auth: AuthTokenPayload | null = null;

  constructor(tokenStorage: TokenStorageInterface) {
    this.storage = tokenStorage;
  }

  async load() {
    this.auth = await this.storage.getItem();
    if (!this.auth) {
      console.warn('No auth information to load');
    }
    return this.auth;
  }

  async getAuth(): Promise<AuthTokenPayload | null> {
    if (!this.auth) {
      await this.load();
    }
    return this.auth;
  }

  async saveAuth(auth: AuthTokenPayload) {
    this.storage.setItem(auth);
    this.auth = auth;
  }

  async getAccessToken() {
    const auth = await this.getAuth();

    return auth ? auth.token : null;
  }

  async getRefreshToken() {
    const auth = await this.getAuth();

    return auth ? auth.refresh : null;
  }

  async clear() {
    await this.storage.removeItem();
  }

  getStorage() {
    return this.storage;
  }
}

export const authTokenStorage = new AuthTokenStorage(tokenStorage);