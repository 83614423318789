import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { AddressState } from '@/store/types';
import { Address, addressRepository, FindOneActionPayload } from '@/store/modules';
// import {AddressRepository} from './repository';
import { normalizeError } from '@/utility';

@Module
export class AddressModule extends VuexModule implements AddressState {

  // fetching: boolean = false;

  @Action
  async findOne(payload: FindOneActionPayload) {
    // this.context.commit('fetching', true);

    Address.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await addressRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }
      const data = { ...response.data };

      // normalize to lowercase
      if (data.Country) {
        data.country = data.Country;
        delete data.Country;
      }

      // normalize to lowercase
      if (response.data.Region) {
        data.region = data.Region;
        delete data.Region;
      }

      Address.insert({ data });

      Address.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      Address.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

    // this.context.commit('fetching', false);
  }
}

export default AddressModule;
// export default getModule(AddressModule);