import axiosProvider from './AxiosProvider';
import { AxiosRequestConfig } from 'axios';
import { authTokenInterceptor, queryInterceptor } from './intercept';
import { AxiosInterceptors } from './types';

const host = process.env.VUE_APP_BASE_HOST;
const protocol = 'https';

const interceptors = {
    request: [queryInterceptor, authTokenInterceptor]
};

const productionProvider = (
    serviceKey: string,
    axiosConfig: AxiosRequestConfig = {},
    otherInterceptors?: AxiosInterceptors
) => axiosProvider(`${protocol}://${serviceKey}.${host}`, axiosConfig,  {
    ...interceptors,
    ...(otherInterceptors || {})
});

export { productionProvider };