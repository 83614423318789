import axios from "axios";
import { AuthResponsePayload, AuthChallengeResponse } from "./types";

const domain = process.env.VUE_APP_AUTH_REPOSITORY_URL;
/**
 * @deprecated use AuthService instead
 */
export default {

  createRequest() {
    const instance = axios.create({
      baseURL: domain
    });
    instance.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('auth__access_token');
        if (token) {
          config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
      }
    );
    return instance;
  },

  async checkLogin() {
    return this.createRequest().get('/');
  },

  async login(username: string, password: string) {
    return this.createRequest().post<AuthResponsePayload>('login', { username, password });
  },

  async logout(username: string) {
    return this.createRequest().post('logout', { username });
  },

  async refresh(token: string) {
    return this.createRequest().post('refresh', { token });
  },

  async change(username: string, password: string, session: string) {
    return this.createRequest().post<AuthChallengeResponse>('change', { username, password, session });
  },
};
