import { AxiosInstance } from "axios";

export class ServiceAbstract {
  private requestInstance: AxiosInstance;

  constructor(request: AxiosInstance) {
    this.requestInstance = request;
  }

  get request() {
    return this.requestInstance;
  }
}