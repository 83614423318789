import { AllPayload, LabourRate, labourRateRepository, CreateLabourRateDto, LabourRateInterface, UpdateLabourRateDto, LabourRateAllPayload } from '@/store/modules';
import { LabourRateState } from '@/store/types';
import { normalizeError } from '@/utility';
import { Action, Module, VuexModule } from 'vuex-module-decorators';

@Module
export class LabourRateModule extends VuexModule implements LabourRateState {

  @Action
  async current() {
    LabourRate.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await labourRateRepository.current();

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;
      await LabourRate.insert({
        data
      });
      // console.log(payload.id, 'part add', data)
      LabourRate.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      LabourRate.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

  }

  @Action({ rawError: true })
  async all(payload: LabourRateAllPayload) {
    LabourRate.commit(state => {
      state.fetching = true;
      state.error.length = 0;
      state.data = [];
    });


    try {
      const { status, data } = await labourRateRepository.all(payload);

      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      // because the api is so inconsistent, we cannot properly enforce typings
      // this is a hack to get the types to stop complaining for now.
      // @ts-ignore
      const results = data as LabourRateInterface[];


      // if (!data || !data.results) {
      //   console.error('Invalid data status codes');
      //   throw 'Invalid response payload.'
      // }

      // const { total, results } = data;

      await LabourRate.create({
        data: results
      });

      LabourRate.commit(state => {
        state.fetching = false;
        // total and pagination is not supported by the api for what ever reason...
        // spoofing the total as the current result length
        state.total = +results.length;
      });

    } catch (err) {
      console.error(err);

      const e = (normalizeError(err) || err);
      LabourRate.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
    }

  }

  @Action
  async findOne(payload: { id: number }) {
    LabourRate.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const response = await labourRateRepository.findOne(payload.id);

      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      await LabourRate.insert({
        data
      });
      // console.log(payload.id, 'part add', data)
      LabourRate.commit(state => {
        state.fetching = false;
      });

    } catch (err) {
      console.error(err);
      // normalizeError
      const e = (normalizeError(err) || err);
      LabourRate.commit(state => {
        state.fetching = false;
        state.error.push(e);
      });
    }

  }

  @Action
  async createLabourRate(payload: { rate: LabourRate }) {
    const { rate } = payload;
    const dto: CreateLabourRateDto = {
      ...rate
    };

    LabourRate.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      const response = await labourRateRepository.create(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      await LabourRate.insert({ data });

      console.log('create  complete');
      LabourRate.commit(state => {
        state.saving = false;
        state.error.length = 0;
        state.lastInsertedId = data.id;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      LabourRate.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async updateLabourRate(payload: { rate: LabourRateInterface }) {
    const { rate } = payload;

    LabourRate.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });
    try {
      if (!rate.id) {
        throw 'Missing part id';
        return;
      }

      const dto: UpdateLabourRateDto = {
        ...rate,
        id: rate.id,
      };

      const response = await labourRateRepository.update(dto);
      if (response.status < 200 && response.status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${response.status}`;
      }

      const data = response.data;

      if (!data) {
        throw 'Invalid response payload.'
      }

      // TODO update response does not return a valid payload. Treating as no content for now. 
      // if it returned the updated part, we can call LabourRate.insertOrUpdate({data: [data]});
      // await LabourRate.insertOrUpdate({data: [data]});

      console.log('put  complete', data);

      LabourRate.commit(state => {
        state.saving = false;
        state.error.length = 0;
      });

    } catch (err) {
      const e = (normalizeError(err) || err);

      LabourRate.commit(state => {
        state.saving = false;
        state.error.push(e);
      });
      // throw e;
    }

  }

  @Action
  async voidLabourRate(payload: { id: number, voided_comment: string }) {
    LabourRate.commit(state => {
      state.saving = true;
      state.error.length = 0;
    });

    if (!payload.id) {
      throw 'Missing labour rate id';
      return;
    }

    const response = await labourRateRepository.void(payload);

    if (response.status < 200 && response.status >= 300) {
      console.error('Invalid 2XX response status');
      throw `Invalid response code: ${response.status}`;
    }

    // await LabourRate.delete(id);

    LabourRate.commit(state => {
      state.saving = false;
      state.error.length = 0;
    });
  }
}

export default LabourRateModule;