import { RouteConfig } from 'vue-router';

// Maintenance Record (MR) components
const TechnicianIndex = () => import(/* webpackChunkName: "technician-group" */ '@/pages/technician/TechnicianIndex.vue')
const TechnicianDetail = () => import(/* webpackChunkName: "technician-group" */ '@/pages/technician/TechnicianDetail.vue')
const TechnicianListHandler = () => import(/* webpackChunkName: "technician-group" */ '@/pages/technician/TechnicianListHandler.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const routes: RouteConfig = {
  path: '/technician',
  // name: 'Technician',
  component: SlidePage,
  redirect: '/technician/',
  children: [
    {
      path: '/',
      component: TechnicianIndex,
      name: 'Technician',
      children: [{
        path: '/',
        component: TechnicianListHandler,
        name: "Technician Records"
      }, {
        path: ':id',
        component: TechnicianDetail,
        props: true,
        name: "Record Detail"
      }]
    },
    {
      path: '*',
      name: 'Task catch all',
      component: UnderConstruction
    }
  ]
};

export default routes;