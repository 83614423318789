import { RouteConfig } from 'vue-router';

// Maintenance Record (MR) components
const LocationIndex = () => import(/* webpackChunkName: "location-group" */ '@/pages/location/LocationIndex.vue')
const TechnicianLocation = () => import(/* webpackChunkName: "location-group" */ '@/pages/location/maps/TechnicianLocation.vue')
const MRMap = () => import(/* webpackChunkName: "location-group" */ '@/pages/location/maps/MRMap.vue')
// const TechnicianDetail = () => import(/* webpackChunkName: "location-group" */ '@/pages/location/TechnicianDetail.vue')
// const TechnicianListHandler = () => import(/* webpackChunkName: "location-group" */ '@/pages/location/TechnicianListHandler.vue')
const SlidePage = () => import('@/pages/SlidePage.vue')
// const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');

const routes: RouteConfig = {
  path: '/location',
  // name: 'Technician',
  component: SlidePage,
  children: [
    {
      path: '/',
      component: LocationIndex,
      name: 'Location',
      redirect: 'tech',
      children: [
        {
          path: 'tech',
          name: 'Technician Location',
          component: TechnicianLocation
        },
        {
          path: 'mr',
          name: 'Task Location',
          component: MRMap
        }
      ]
    }
  ]
};

export default routes;