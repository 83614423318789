import { AxiosInterceptors, RequestInterceptors } from '../types';
import { AxiosRequestConfig } from 'axios';
import qs from 'qs';

export const queryInterceptor: RequestInterceptors<AxiosRequestConfig> = {
    resolve: (config) => {
        // fix url quest not serializing properly with nested array and objects
        config.paramsSerializer = params => {
            return qs.stringify(params);
        };
        
        return config;
    }
}