import { Model } from '@vuex-orm/core';
import { MR } from '../MaintenanceRecord';
import { GeoLocation } from '../GeoLocation';
import { Timesheet } from '../Timesheet';
import { TaskSchedule } from '../TaskSchedule';
import { PartRequest } from '../PartRequest';
import { User } from '../User';

export interface TechnicianInterface {
  ID: number;
  employee_num?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  cell?: string;
  address?: string;
  notes?: string;
  po_flag?: boolean;
  employee_departmentID?: number;
  department_position?: number | string;
  maintenanceRecords?: MR[];
  geoLocations?: GeoLocation[];
  cognitoID?: string | null;
}

export class Technician extends Model implements TechnicianInterface {
  ID!: number;
  maintenanceRecords?: MR[];
  geoLocations?: GeoLocation[];
  employee_num?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  cell?: string;
  address?: string;
  notes?: string;
  po_flag?: boolean;
  employee_departmentID?: number;
  department_position?: number | string;
  cognitoID?: string | null;

  static entity = 'technician';
  static primaryKey = 'ID'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  static fields() {
    return {
      ID: this.attr(null),
      employee_num: this.number(null).nullable(),
      firstname: this.string(null).nullable(),
      lastname: this.string(null).nullable(),
      email: this.string(null).nullable(),
      phone: this.string(null).nullable(),
      cell: this.string(null).nullable(),
      address: this.string(null).nullable(),
      notes: this.string(null).nullable(),
      po_flag: this.boolean(null).nullable(),
      cognitoID: this.string(null).nullable(),
      employee_departmentID: this.number(null).nullable(),
      department_position: this.number(null).nullable(),
      maintenanceRecords: this.hasMany(MR, 'techID'),
      geoLocations: this.hasMany(GeoLocation, 'tech_id'),
      timesheets: this.hasMany(Timesheet, 'techID'),
      taskSchedules: this.hasMany(TaskSchedule, 'techID'),
      partRequests: this.hasMany(PartRequest, 'tech_id'),
      // user: this.belongsTo(User, 'user_id')
    }
  }

  get hexColor() {
    // https://www.designedbyaturtle.co.uk/convert-string-to-hexidecimal-colour-with-javascript-vanilla/
    const i = this.name
      .split("")
      .map(s => s.charCodeAt(0))
      .reduce((acc, s) => {
        return s + (acc << 5) - acc;
      }, 0);

    let hex =
      ((i >> 24) & 0xff).toString(16) +
      ((i >> 16) & 0xff).toString(16) +
      ((i >> 8) & 0xff).toString(16) +
      (i & 0xff).toString(16);

    // Sometimes the string returned will be too short so we
    // add zeros to pad it out, which later get removed if
    // the length is greater than six.
    hex += "000000";

    return hex.substring(0, 6);
  }

  get name() {
    return `${this.firstname} ${this.lastname}`;
  }


}

export default Technician;