import { Model } from '@vuex-orm/core';

export interface MRNoteInterface {
  id: number;
  note: string;
  mrID: number | null;
  techID: number | null;
  tech_name?: string |null;
  created_at?: string;
  updated_at?: string;
}

export class MRNote extends Model implements MRNoteInterface {

  id!: number;
  note!: string;

  mrID!: number | null;
  techID!: number | null;
  tech_name?: string |null;
  created_at?: string;
  updated_at?: string;

  static entity = 'mr_note';
  static primaryKey = 'id'

  static state() {
    return {
      total: 0,
      fetching: false,
      error: []
    }
  }

  total() {
    return this
  }

  static fields() {
    return {
      id: this.attr(null),
      note: this.string(''),
      mrID: this.number(null).nullable(),
      techID: this.number(null).nullable(),
      tech_name: this.string(null).nullable(),
      created_at: this.string(null).nullable(),
      updated_at: this.string(null).nullable()
    };
  }
}