import { VuexModule, Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import { normalizeError } from '@/utility';
import { Manufacturer, manufacturerRepository, AllPayload, ManufacturerAllPayload } from '@/store/modules';
import { ManufacturerInterface } from './Manufacturer';

@Module
export class ManufacturerModule extends VuexModule {

  @Action
  async all(payload: ManufacturerAllPayload) {
    Manufacturer.commit(state => {
      state.fetching = true;
      state.error.length = 0;
    });

    try {
      const { status, data } = await manufacturerRepository.all(payload);
      if (status < 200 && status >= 300) {
        console.error('Invalid 2XX response status');
        throw `Invalid response code: ${status}`;
      }

      if (!data || !data.results) {
        console.error('Invalid data status codes');
        throw 'Invalid response payload.'
      }

      const { results } = data;

      Manufacturer.create({
        data: results.map(manufacturer => {
          if ('manufacturer' in manufacturer) {
            manufacturer['name'] = manufacturer['manufacturer'];
            delete manufacturer['manufacturer'];
          }
          return manufacturer as ManufacturerInterface;
        })
      });

      Manufacturer.commit(state => {
        state.fetching = false;
        state.total = +results.length;
      });

    } catch (err) {
      console.error(err);
      const e = (normalizeError(err) || err);

      Manufacturer.commit(state => {
        state.fetching = false;
        state.total = 0;
        state.error.push(e);
      });
      // throw e;
    }

  }
}

export default ManufacturerModule;