import { RouteConfig } from 'vue-router'

// Maintenance Record (MR) components
const PartIndex = () => import(/* webpackChunkName: "part-group" */ '@/pages/part/PartIndex.vue')
const PartDetail = () => import(/* webpackChunkName: "part-group" */ '@/pages/part/PartDetail.vue')
const PartListHandler = () => import(/* webpackChunkName: "part-group" */ '@/pages/part/PartListHandler.vue')
const PartAdd = () => import(/* webpackChunkName: "part-group" */ '@/pages/part/PartAdd.vue')
const PartEdit = () => import(/* webpackChunkName: "part-group" */ '@/pages/part/PartEdit.vue')

const PartCategoryIndex = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-category/PartCategoryIndex.vue')
const PartCategoryDetail = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-category/PartCategoryDetail.vue')
const PartCategoryListHandler = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-category/PartCategoryListHandler.vue')
const PartCategoryAdd = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-category/PartCategoryAdd.vue')
const PartCategoryEdit = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-category/PartCategoryEdit.vue')

const PartRequestIndex = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-request/PartRequestIndex.vue')
const PartRequestDetail = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-request/PartRequestDetail.vue')
const PartRequestListHandler = () => import(/* webpackChunkName: "part-group" */ '@/pages/part-request/PartRequestListHandler.vue')

const SlidePage = () => import('@/pages/SlidePage.vue')
const UnderConstruction = () => import(/* webpackChunkName: "generic-pages-group" */'@/pages/UnderConstruction.vue');
const route: RouteConfig[] = [{
  path: 'part',
  // name: 'Maintenance Records',
  component: SlidePage,
  children: [
    {
      path: '/',
      component: PartIndex,
      name: "Part",
      children: [{
        path: '/',
        component: PartListHandler,
        name: "Part"
      }, {
        path: 'add',
        component: PartAdd,
        props: true,
        name: "Part Add"
      }, {
        path: ':id/edit',
        component: PartEdit,
        props: true,
        name: "Part Edit"
      }, {
        path: ':id',
        component: PartDetail,
        props: true,
        name: "Part Detail"
      }]
    },

    {
      path: '*',
      name: 'Part',
      component: UnderConstruction
    }
  ],
},
{
  path: 'part-request',
  component: SlidePage,
  redirect: '/part-request/',
  children: [
    {
      path: '/',
      component: PartRequestIndex,
      name: "Part",
      children: [{
        path: '/',
        component: PartRequestListHandler,
        name: "Part"
      },
      {
        path: ':id',
        component: PartRequestDetail,
        props: true,
        name: "Part"
      }]
    },
  ]
},
{
  path: 'part-category',
  // name: 'Maintenance Records',
  component: SlidePage,
  redirect: '/part-category/',
  children: [
    {
      path: '/',
      component: PartCategoryIndex,
      name: "Categories",
      children: [{
        path: '/',
        component: PartCategoryListHandler,
        name: "Categories"
      }, {
        path: 'add',
        component: PartCategoryAdd,
        props: true,
        name: "Category Add"
      }, {
        path: ':id/edit',
        component: PartCategoryEdit,
        props: true,
        name: "Category Edit"
      }, {
        path: ':id',
        component: PartCategoryDetail,
        props: true,
        name: "Category Detail"
      }]
    },

    {
      path: '*',
      name: 'Part',
      component: UnderConstruction
    }
  ],



}]

export default route;